import {
  InMemoryCache, ReactiveVar, makeVar,
} from '@apollo/client';
import { dtRangeChoices } from 'src/apps/reports/Filters/entities/dateRange';

const defaultDtRange = dtRangeChoices.find((dtRange) => dtRange.id === 'thisWeek');
export const localFromDatetime: ReactiveVar<string> = makeVar(defaultDtRange?.filter?.fromDatetime || '');
export const localToDatetime: ReactiveVar<string> = makeVar(defaultDtRange?.filter?.toDatetime || '');
export const localCompanyID = makeVar<string>('');
export const localCompanyBranchIds = makeVar<string[]>([]);
export const localProjectIds = makeVar<string[]>([]);
export const localAllBranchesChecked = makeVar(false);
export const localAllProjectsChecked = makeVar(false);
export const localLoadingStatus = makeVar<boolean[]>([]);

export const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        fromDatetime: {
          read() {
            return localFromDatetime();
          },
        },
        toDatetime: {
          read() {
            return localToDatetime();
          },
        },
        companyID: {
          read() {
            return localCompanyID();
          },
        },
        companyBranchIds: {
          read() {
            return localAllBranchesChecked() ? [] : localCompanyBranchIds();
          },
        },
        projectIds: {
          read() {
            return localAllBranchesChecked() && localAllProjectsChecked() ? [] : localProjectIds();
          },
        },
        allBranchesChecked: {
          read() {
            return localAllBranchesChecked();
          },
        },
        allProjectsChecked: {
          read() {
            return localAllProjectsChecked();
          },
        },
        loadingStatus: {
          read() {
            return localLoadingStatus();
          },
        },
      },
    },
  },
});
