import { createStyles, makeStyles } from '@material-ui/core';
import { $colors, $fontSize, $fontWeight } from 'src/styles/variables';

export const useStyles = makeStyles(() => createStyles({
  announcements: {
    border: `2px solid ${$colors.mHubTeal}`,
    color: $colors.mHubTeal,
  },
  chip: {
    width: '100%',
    '& > .MuiChip-root': {
      fontSize: $fontSize['12px'],
      fontWeight: $fontWeight.semiBold,
      margin: '1rem 0 0.5rem',
      '& .new, & .annoucements': {
        border: `2px solid ${$colors.mHubTeal}`,
        color: $colors.mHubWhite,
      },
      '& .improvement': {
        border: `2px solid ${$colors.mHubMustard}`,
        color: $colors.mHubWhite,
      },
    },
  },
  container: {
    padding: '2rem',
  },
  header: {
    '& h1': {
      marginBottom: '0.5rem',
    },
    '& p': {
      color: $colors.mHubSlate,
    },
  },
  improvement: {
    border: `2px solid ${$colors.mHubWarning}`,
    color: $colors.mHubWarning,
  },
  new: {
    border: `2px solid ${$colors.mHubTeal}`,
    color: $colors.mHubTeal,
  },
  notes: {
    '& ul > li > p': {
      color: $colors.mHubSlate,
    },
    '& li + li': {
      marginTop: '0.5rem',
    },
    '& ul': {
      margin: '0.5rem 0',
    },
  },
  section: {
    borderTop: `1px solid ${$colors.mHubGray5}`,
    marginTop: '2rem',
    paddingTop: '2rem',
  },
  versionContainer: {
    '& > h5': {
      color: $colors.mHubSlate,
      marginRight: '1rem',
    },
    '& > p': {
      color: $colors.mHubGray1,
    },
  },
}));
