export enum PropertyCategoryID {
  APARTMENT = 'apartment',
  BUSINESS_CENTRE = 'business_centre',
  BUNGALOW = 'bungalow',
  BUNGALOW_LAND = 'bungalow_land',
  CLUSTER_HOUSE = 'cluster_house',
  COMMERCIAL_LAND = 'commercial_land',
  CONDOMINIUM = 'condominium',
  DESIGNER_SUITES = 'designer_suites',
  DETACHED_FACTORY = 'detached_factory',
  FACTORY = 'factory',
  FLAT = 'flat',
  HOME_LAND = 'home_land',
  INDUSTRIAL_LAND = 'industrial_land',
  LIGHT_INDUSTRIAL = 'light_industrial',
  LINK_FACTORY = 'link_factory',
  OFFICE = 'office',
  RESIDENTIAL_LAND = 'residential_land',
  RETAIL_OFFICE = 'retail_office',
  RETAIL_SPACE = 'retail_space',
  SEMI_D = 'semi_d',
  SEMI_D_FACTORY = 'semi_d_factory',
  SERVICE_RESIDENCE = 'service_residence',
  SOFO = 'sofo',
  SOHO = 'soho',
  SOVO = 'sovo',
  SHOP = 'shop',
  SHOP_OFFICE = 'shop_office',
  SUPERLINK_HOMES = 'superlink_homes',
  TERRACE = 'terrace',
  TOWNHOUSE = 'townhouse',
  VILLA = 'villa',
  WAREHOUSE = 'warehouse',
}

export enum PropertyCategoryType {
  HIGH_RISE = 'high_rise',
  LANDED = 'landed',
}

export class PropertyCategory {
  readonly id: PropertyCategoryID;

  readonly category: PropertyCategoryType;

  readonly name: string;

  constructor({
    id,
    category,
    name,
  }: {
    id?: PropertyCategoryID,
    category?: PropertyCategoryType,
    name?: string,
  } = {}) {
    this.id = id ?? PropertyCategoryID.APARTMENT;
    this.category = category ?? PropertyCategoryType.HIGH_RISE;
    this.name = name ?? '';
  }
}
