import { CompanyApp } from './companyApp';

export default class Company {
  readonly id: string;

  readonly code: string;

  readonly name: string;

  readonly shortName: string;

  readonly companyType: string;

  readonly address1: string;

  readonly address2: string;

  readonly address3: string;

  readonly address4: string;

  readonly postcode: string;

  readonly city: string;

  readonly state: string;

  readonly country: string;

  readonly countryCode: string;

  readonly gps: string;

  readonly email: string;

  readonly phone: string;

  readonly fax: string;

  readonly bucketName: string;

  readonly webAwsRegion: string;

  readonly webAwsBucket: string;

  readonly webAwsSiteplanRegion: string;

  readonly webAwsSiteplanBucket: string;

  readonly webMhubLoader: boolean;

  readonly currencyCode: string;

  readonly currencySymbol: string;

  readonly paymentIsEnabled: boolean;

  readonly paymsPaymentGateway: string;

  readonly paymsBillplzCollectionID: string;

  readonly paymsBillplzCollectionTitle: string;

  readonly paymsBillplzSecretKey: string;

  readonly paymsBillplzXSignatureKey: string;

  readonly paymsIPay88MerchantCode: string;

  readonly paymsIPay88MerchantKey: string;

  readonly paymsPayDollarMerchantID: string;

  readonly paymsPayDollarSecureHashSecret: string;

  readonly paymsUPayMerchantID: string;

  readonly paymsUPaySecureHashSecret: string;

  readonly lawyerIsEnabled: boolean;

  readonly bmsToken: string;

  readonly service_company_id: string;

  readonly customAppName: string;

  readonly customLogoSrc: string;

  readonly isAgency: boolean;

  readonly headquartersBranchID: number;

  readonly paymentAPIUrl: string;

  readonly paymentPayeeID: string;

  readonly paymentPayeeToken: string;

  readonly apps: CompanyApp[] = [];

  readonly oldID: number = 0;

  constructor({
    id,
    code,
    name,
    shortName,
    companyType,
    address1,
    address2,
    address3,
    address4,
    postcode,
    city,
    state,
    country,
    countryCode,
    gps,
    email,
    phone,
    fax,
    bucketName,
    webAwsRegion,
    webAwsBucket,
    webAwsSiteplanRegion,
    webAwsSiteplanBucket,
    webMhubLoader,
    currencyCode,
    currencySymbol,
    paymentIsEnabled,
    paymsPaymentGateway,
    paymsBillplzCollectionID,
    paymsBillplzCollectionTitle,
    paymsBillplzSecretKey,
    paymsBillplzXSignatureKey,
    paymsIPay88MerchantCode,
    paymsIPay88MerchantKey,
    paymsPayDollarMerchantID,
    paymsPayDollarSecureHashSecret,
    paymsUPayMerchantID,
    paymsUPaySecureHashSecret,
    lawyerIsEnabled,
    bmsToken,
    service_company_id,
    customAppName,
    customLogoSrc,
    isAgency,
    headquartersBranchID,
    paymentAPIUrl,
    paymentPayeeID,
    paymentPayeeToken,
    apps,
    oldID,
  } : {
    id?: string,
    code?: string,
    name?: string,
    shortName?: string,
    companyType?: string,
    address1?: string,
    address2?: string,
    address3?: string,
    address4?: string,
    postcode?: string,
    city?: string,
    state?: string,
    country?: string,
    countryCode?: string,
    gps?: string,
    email?: string,
    phone?: string,
    fax?: string,
    bucketName?: string,
    webAwsRegion?: string,
    webAwsBucket?: string,
    webAwsSiteplanRegion?: string,
    webAwsSiteplanBucket?: string,
    webMhubLoader?: boolean,
    currencyCode?: string,
    currencySymbol?: string,
    paymentIsEnabled?: boolean,
    paymsPaymentGateway?: string,
    paymsBillplzCollectionID?: string,
    paymsBillplzCollectionTitle?: string,
    paymsBillplzSecretKey?: string,
    paymsBillplzXSignatureKey?: string,
    paymsIPay88MerchantCode?: string,
    paymsIPay88MerchantKey?: string,
    paymsPayDollarMerchantID?: string,
    paymsPayDollarSecureHashSecret?: string,
    paymsUPayMerchantID?: string,
    paymsUPaySecureHashSecret?: string,
    lawyerIsEnabled?: boolean,
    bmsToken?: string,
    service_company_id?: string,
    customAppName?: string,
    customLogoSrc?: string,
    isAgency?: boolean,
    headquartersBranchID?: number,
    paymentAPIUrl?: string,
    paymentPayeeID?: string,
    paymentPayeeToken?: string,
    apps?: CompanyApp[],
    oldID?: number,
  } = {}) {
    this.id = id ?? '';
    this.code = code ?? '';
    this.name = name ?? '';
    this.shortName = shortName ?? '';
    this.companyType = companyType ?? '';
    this.address1 = address1 ?? '';
    this.address2 = address2 ?? '';
    this.address3 = address3 ?? '';
    this.address4 = address4 ?? '';
    this.postcode = postcode ?? '';
    this.city = city ?? '';
    this.state = state ?? '';
    this.country = country ?? '';
    this.countryCode = countryCode ?? 'my';
    this.gps = gps ?? '';
    this.email = email ?? '';
    this.phone = phone ?? '';
    this.fax = fax ?? '';
    this.bucketName = bucketName ?? '';
    this.webAwsRegion = webAwsRegion ?? '';
    this.webAwsBucket = webAwsBucket ?? '';
    this.webAwsSiteplanRegion = webAwsSiteplanRegion ?? '';
    this.webAwsSiteplanBucket = webAwsSiteplanBucket ?? '';
    this.webMhubLoader = webMhubLoader ?? false;
    this.currencyCode = currencyCode ?? '';
    this.currencySymbol = currencySymbol ?? '';
    this.paymentIsEnabled = paymentIsEnabled ?? false;
    this.paymsPaymentGateway = paymsPaymentGateway ?? '';
    this.paymsBillplzCollectionID = paymsBillplzCollectionID ?? '';
    this.paymsBillplzCollectionTitle = paymsBillplzCollectionTitle ?? '';
    this.paymsBillplzSecretKey = paymsBillplzSecretKey ?? '';
    this.paymsBillplzXSignatureKey = paymsBillplzXSignatureKey ?? '';
    this.paymsIPay88MerchantCode = paymsIPay88MerchantCode ?? '';
    this.paymsIPay88MerchantKey = paymsIPay88MerchantKey ?? '';
    this.paymsPayDollarMerchantID = paymsPayDollarMerchantID ?? '';
    this.paymsPayDollarSecureHashSecret = paymsPayDollarSecureHashSecret ?? '';
    this.paymsUPayMerchantID = paymsUPayMerchantID ?? '';
    this.paymsUPaySecureHashSecret = paymsUPaySecureHashSecret ?? '';
    this.lawyerIsEnabled = lawyerIsEnabled ?? false;
    this.bmsToken = bmsToken ?? '';
    this.service_company_id = service_company_id ?? '';
    this.customAppName = customAppName ?? '';
    this.customLogoSrc = customLogoSrc ?? '';
    this.isAgency = isAgency ?? false;
    this.headquartersBranchID = headquartersBranchID ?? 0;
    this.paymentAPIUrl = paymentAPIUrl ?? '';
    this.paymentPayeeID = paymentPayeeID ?? '';
    this.paymentPayeeToken = paymentPayeeToken ?? '';
    this.apps = apps ?? [];
    this.oldID = oldID ?? 0;
  }
}
