import CountryStateEntity from '../entities/countryStateEntity';

export default class CountryState {
  readonly label: string;

  readonly value: string;

  constructor({
    label,
    value,
  }: {
    label?: string,
    value?: string,
  } = {}) {
    this.label = label ?? '';
    this.value = value ?? '';
  }

  static fromEntity(entity: CountryStateEntity): CountryState {
    return new CountryState({
      label: entity.label,
      value: entity.value,
    });
  }
}
