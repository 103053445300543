export const paymentMethods = [
  { text: 'Panel Banker', value: 'referred_banker' },
  { text: 'Preferred Banker', value: 'own_banker' },
  { text: 'Cash Buyer', value: 'cash' },
  { text: 'Government Loan', value: 'government_loan' },
  { text: 'Staff Loan', value: 'staff_loan' },
  { text: 'Rent-To-Own', value: 'rent_to_own' },
];

export const paymentTypes = [
  { text: 'Bank Transfer', value: 'bank_transfer' },
  { text: 'Cheque', value: 'cheque' },
  { text: 'Cash', value: 'cash' },
  { text: 'Credit Card', value: 'credit_card' },
  { text: 'MHub Pay', value: 'online_payment' },
  { text: 'Others', value: 'others' },
];

export const paymentStatuses = [
  { text: 'Unpaid', value: 'unpaid' },
  { text: 'Partially Paid', value: 'partial_paid' },
  { text: 'Paid', value: 'paid' },
  { text: 'Refunded', value: 'refunded' },
];
