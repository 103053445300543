interface ITag {
  label: string,
  value: string,
}

interface IUpdates {
  category: string,
  notes: string[],
}

interface IContents {
  version: string,
  release: string,
  updates: IUpdates[],
}

export const infoChipTags: ITag[] = [
  { label: 'Announcements', value: 'announcements' },
  { label: 'New features', value: 'new' },
  { label: 'Improvements', value: 'improvement' },
];

export const newInfo: IContents[] = [
  {
    version: '1.9.8',
    release: 'Feb 13, 2024',
    updates: [
      {
        category: 'new',
        notes: [
          'Add Download page for custom report',
        ],
      },
    ],
  },
  {
    version: '1.9.7',
    release: 'Jan 11, 2024',
    updates: [
      {
        category: 'new',
        notes: [
          'Added 3 new charts in Marketing overview',
        ],
      },
    ],
  },
  {
    version: '1.9.6',
    release: 'Dec 29, 2023',
    updates: [
      {
        category: 'new',
        notes: [
          'Added 5 new charts in Marketing overview',
          "Added 'Lead flow pipeline' in Marketing overview",
        ],
      },
    ],
  },
  {
    version: '1.9.2',
    release: 'Aug 22, 2023',
    updates: [
      {
        category: 'improvement',
        notes: [
          'Revised the logic of the Conversion Rate chart on Sales overview',
          'Moved Loan and SPA & LA charts to Sales',
        ],
      },
    ],
  },
  {
    version: '1.9.0',
    release: 'Jun 09, 2023',
    updates: [
      {
        category: 'improvement',
        notes: [
          'Fixed a bug for Lawyer analytics charts showing "Connection read timed out" error',
          'Improved page switching lags',
        ],
      },
      {
        category: 'new',
        notes: [
          'Added a "No access" page if Analytics is accessed by a user without permission',
        ],
      },
    ],
  },
  {
    version: '1.8.0',
    release: 'Apr 05, 2023',
    updates: [
      {
        category: 'improvement',
        notes: [
          'Fixed a bug that showed incorrect values on the Sales won (Total GDV Sold) chart',
          'Improved page switching lags',
          'Changed the Business Unit label on top filter to Project Ownership',
        ],
      },
      {
        category: 'new',
        notes: [
          'Added the Credit Check usage chart',
          'Split the Marketing Overview - Split New Leads into 2 charts',
          'Split the Marketing Overview - Split Won Opportunities into 2 charts',
          'Added the Sales Overview - Non-qualified Customers Sales Funnel chart',
        ],
      },
    ],
  },
  {
    version: '1.7.0',
    release: 'Jan 18, 2023',
    updates: [
      {
        category: 'improvement',
        notes: [
          'Fixed a bug on the Opportunities by Assignee chart where wrong assignee names appeared',
        ],
      },
      {
        category: 'new',
        notes: [
          '3 new charts for Project > Overview',
          '1 new section (Credit Check) added with 1 new page (Overview)',
        ],
      },
    ],
  },
  {
    version: '1.6.0',
    release: 'Oct 27, 2022',
    updates: [
      {
        category: 'new',
        notes: [
          'v1.6.0 - Added Customer Demographics and Lead Demographics under the Marketing section',
        ],
      },
    ],
  },
  {
    version: '1.5.0',
    release: 'Sep 15, 2022',
    updates: [
      {
        category: 'new',
        notes: [
          'v1.5.0 New page deployed: Project Overview',
        ],
      },
    ],
  },
];
