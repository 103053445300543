import {
  createContext, ReactNode, useContext, useState,
} from 'react';

interface IFilterContext {
  isBusinessUnitChange: boolean;
  isCompanyIDChange: boolean;
  isDateRangeChange: boolean;
  isPageChange: boolean;
  isProjectNameChange: boolean;
  isChartError: boolean;
  currentActivePage: string;
  setIsBusinessUnitChange: (val: boolean) => void;
  setIsCompanyIDChange: (val: boolean) => void;
  setIsDateRangeChange: (val: boolean) => void;
  setIsPageChange: (val: boolean) => void;
  setIsProjectNameChange: (val: boolean) => void;
  setIsChartError: (val: any) => void;
  setCurrentActivePage: (val: any) => void;
}

const FilterContext = createContext<IFilterContext>({
  isBusinessUnitChange: false,
  isCompanyIDChange: false,
  isDateRangeChange: false,
  isPageChange: false,
  isProjectNameChange: false,
  isChartError: false,
  currentActivePage: '',
  setIsBusinessUnitChange: () => { },
  setIsCompanyIDChange: () => { },
  setIsDateRangeChange: () => { },
  setIsPageChange: () => { },
  setIsProjectNameChange: () => { },
  setIsChartError: () => { },
  setCurrentActivePage: () => { },
});

export function useProvideFilter(): IFilterContext {
  const [isBusinessUnitChange, setCheckBusinessUnitChange] = useState(false);
  const [isCompanyIDChange, setCheckCompanyIDChange] = useState(false);
  const [isDateRangeChange, setCheckDateRangeChange] = useState(false);
  const [isPageChange, setCheckPageChange] = useState(false);
  const [isProjectNameChange, setCheckProjectNameChange] = useState(false);
  const [isChartError, setCheckChartError] = useState(false);
  const [currentActivePage, setActivePage] = useState('');

  const setIsBusinessUnitChange = (state: boolean) => {
    setCheckBusinessUnitChange(state);
  };

  const setIsCompanyIDChange = (state: boolean) => {
    setCheckCompanyIDChange(state);
  };

  const setIsDateRangeChange = (state: boolean) => {
    setCheckDateRangeChange(state);
  };

  const setIsPageChange = (state: boolean) => {
    setCheckPageChange(state);
  };

  const setIsProjectNameChange = (state: boolean) => {
    setCheckProjectNameChange(state);
  };

  const setIsChartError = (state: boolean) => {
    setCheckChartError(state);
  };

  const setCurrentActivePage = (active: string) => {
    setActivePage(active);
  };

  return {
    isBusinessUnitChange,
    isCompanyIDChange,
    isDateRangeChange,
    isPageChange,
    isProjectNameChange,
    isChartError,
    currentActivePage,
    setIsBusinessUnitChange,
    setIsCompanyIDChange,
    setIsDateRangeChange,
    setIsPageChange,
    setIsProjectNameChange,
    setIsChartError,
    setCurrentActivePage,
  };
}

export const useFilterContext = () => useContext(FilterContext);

export function FilterProvider({
  children,
}: {
  children: ReactNode,
}) {
  const value = useProvideFilter();
  return (
    <FilterContext.Provider value={value}>
      {children}
    </FilterContext.Provider>
  );
}
