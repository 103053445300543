import { createStyles, makeStyles } from '@material-ui/core';
import { $colors } from 'src/styles/variables';

export const useStyles = makeStyles(() => createStyles({
  alertIcon: {
    fill: $colors.mHubPeach,
  },
  bottomLeft: {
    bottom: '10px',
  },
  checkmarkFilledIcon: {
    fill: $colors.mHubMint,
  },
  fullWidthPosition: {
    bottom: '24px',
    left: '24px',
  },
  message: {
    whiteSpace: 'pre',
    lineHeight: 1,
  },
  // base on 400px width drawer
  drawerAlert: {
    width: '350px',
    bottom: '140px', // prevent blocking drawer button
    '& .MuiAlert-message': {
      width: '250px',
    },
    '& .breakLine': {
      lineHeight: 1.5,
      whiteSpace: 'break-spaces',
    },
  },
}));
