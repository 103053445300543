import qs from 'query-string';
import Company from 'src/models/company';

const {
  REACT_APP_S3_SITEPLAN_BUCKET,
  REACT_APP_S3_SITEPLAN_REGION,
  REACT_APP_RELEASE_STAGE,
} = process.env;

export const getQuery = (location = window.location): any => qs.parse(location.search);

export function encodeRedirect(url = '/') {
  let nextBase64 = '';
  if (url) {
    try {
      nextBase64 = btoa(url);
    } catch (e) {
      // window.bugsnagClient.notify(e);
    }
  }
  return nextBase64;
}

export function decodeRedirect(nextBase64: string) {
  let url = '';
  if (nextBase64) {
    try {
      url = atob(nextBase64);
    } catch (e) {
      // window.bugsnagClient.notify(e);
    }
  }
  return url;
}

export function parseRedirect(nextBase64: string) {
  return `?redirect=${nextBase64}`;
}

export function parseParams(url: string, query = {}, hash = {}) {
  let parsedURL = url;
  if (query && Object.keys(query).length > 0) {
    parsedURL += `?${qs.stringify(query)}`;
  }
  if (hash && Object.keys(hash).length > 0) {
    parsedURL += `#${qs.stringify(hash)}`;
  }
  return parsedURL;
}

/**
 * Generate stage according to MHub Service API convention.
 * - Dev: https://service-api.dev.mhub.my
 * - Staging: https://service-api.stage.mhub.my
 * - Demo: https://service-api.demo.mhub.my
 * - Prod: https://service-api.mhub.my
 *
 * Example:
 * - `generateStage('dev')` will return `".dev"
 * - `generateStage('prod')` will return `""`
 * - `generateStage('prod', { include: true })` will return `".prod"`
 */
export function generateStage(
  stage: string,
  {
    include,
    noDev,
  } : {
    include: boolean,
    noDev: boolean,
  } = {
    include: false,
    noDev: false,
  },
): string {
  if (stage === 'prod' && !include) {
    return '';
  }
  return `.${noDev ? 'stage' : stage}`;
}

export function getSiteplanS3Url(fileUrl: string, bucket = '', region = '') {
  let s3Bucket = REACT_APP_S3_SITEPLAN_BUCKET;
  let s3Region = REACT_APP_S3_SITEPLAN_REGION;

  if (bucket) {
    s3Bucket = bucket;
  }
  if (region) {
    s3Region = region;
  }

  if (s3Bucket && s3Region) {
    return `https://${s3Bucket}.s3.${s3Region}.amazonaws.com/${fileUrl}`;
  }
  return '';
}

export function getAppUrl(company: Company, id: string) {
  if (company && company.apps) {
    const { apps } = company;
    if (apps && apps.length > 0) {
      const isExist = apps.some((a:any) => a.appID === id);
      if (isExist) {
        const obj = apps.find((o:any) => o.appID === id);
        return obj?.webURL;
      }
    }
  }
  return '';
}

export function constructIdentityUrl(
  company: Company,
  awsKey?: string,
): string {
  if (awsKey && company) {
    return `https://mhub-box-assets-stage.s3-ap-southeast-1.amazonaws.com/${awsKey}`;
  }
  return '';
}

export function constructS3Url(
  company: Company,
  awsKey?: string,
): string {
  if (awsKey && company) {
    return `https://s3.${company.webAwsRegion}.amazonaws.com/${company.webAwsBucket}/${awsKey}`;
  }
  return '';
}

export function getPicturesS3Url(id: any, company: Company, pictureSize?: string, pictureType?: string) {
  const type = pictureType ?? 'jpg';
  let size = '010';
  if (pictureSize) {
    size = pictureSize;
    if (Number(size) < 100) {
      size = '0'.concat(size);
    }
  }
  if (id && company) {
    return `https://${company.webAwsBucket}.s3.${company.webAwsRegion}.amazonaws.com/${id}${size}.${type}`;
  }
  return '';
}

export const isProd = ['prod'].includes(REACT_APP_RELEASE_STAGE);
export const isDemo = ['demo'].includes(REACT_APP_RELEASE_STAGE);

export function isValidUrl(url: string) {
  // eslint-disable-next-line
  const regexp = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
  return regexp.test(url);
}
