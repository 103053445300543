export const STATES = [
  { value: 'Johor', text: 'Johor' },
  { value: 'Kedah', text: 'Kedah' },
  { value: 'Kelantan', text: 'Kelantan' },
  { value: 'Kuala Lumpur', text: 'Wilayah Persekutuan Kuala Lumpur' },
  { value: 'Labuan', text: 'Wilayah Persekutuan Labuan' },
  { value: 'Malacca', text: 'Melaka' },
  { value: 'Negeri Sembilan', text: 'Negeri Sembilan' },
  { value: 'Pahang', text: 'Pahang' },
  { value: 'Penang', text: 'Pulau Pinang' },
  { value: 'Perak', text: 'Perak' },
  { value: 'Perlis', text: 'Perlis' },
  { value: 'Putrajaya', text: 'Wilayah Persekutuan Putrajaya' },
  { value: 'Sabah', text: 'Sabah' },
  { value: 'Sarawak', text: 'Sarawak' },
  { value: 'Selangor', text: 'Selangor' },
  { value: 'Terengganu', text: 'Terengganu' },
];

export default STATES;
