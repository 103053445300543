import {
  createStyles, makeStyles, Theme,
} from '@material-ui/core/styles';
import {
  $fontFamily,
  $fontWeight,
  $colors,
} from './variables';

export const formStyles = makeStyles((theme: Theme) => createStyles({
  backIcon: {
    color: $colors.mHubSlate,
    minWidth: '0 !important',
    marginRight: '10px',
    '&:hover': {
      background: $colors.mHubGray3,
      borderRadius: '50%',
    },
  },
  basicButton: {
    fontSize: '14px',
    fontFamily: $fontFamily.openSans,
    fontWeight: $fontWeight.semiBold,
    padding: '8px 14px',
    '&:hover': {
      boxShadow: 'none !important',
    },
  },
  basicDrawerBox: {
    height: '100%',
    position: 'relative',
  },
  basicDrawerGrid: {
    minHeight: 'calc(100% - 80px)',
    padding: '24px 24px 104px 24px',
  },
  basicTealButton: {
    color: $colors.mHubTeal,
    fontFamily: $fontFamily.openSans,
    fontWeight: $fontWeight.semiBold,
    fontSize: '14px',
    lineHeight: '19.07px',
    '&:hover': {
      background: 'transparent',
      color: $colors.mHubTealHover,
      textDecoration: 'underline',
    },
  },
  disabledSwitch: {
    '& .MuiSwitch-thumb': {
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.10)',
    },
  },
  label: {
    fontSize: '12px',
    fontFamily: $fontFamily.openSans,
    color: $colors.mHubSpace,
    fontWeight: $fontWeight.semiBold,
  },
  media: {
    boxShadow: 'none',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '50px',
    '& .MuiCardMedia-root': {
      height: '140px',
      width: '150px',
    },
  },
  multilineOutlineInput: {
    '& .MuiOutlinedInput-root': {
      height: 'auto',
    },
  },
  required: {
    '& p': {
      color: '#FF0000',
      fontSize: '12px',
      fontFamily: $fontFamily.openSans,
      fontWeight: $fontWeight.semiBold,
      lineHeight: '16px',
    },
  },
  section: {
    marginTop: '30px',
    fontSize: '15px',
    fontFamily: $fontFamily.montserrat,
    color: $colors.mHubSpace,
    fontWeight: $fontWeight.semiBold,
  },
  switchLabel: {
    height: '100%',
    '& .MuiTypography-root': {
      color: $colors.mHubSlate,
      fontFamily: $fontFamily.openSans,
      fontSize: '14px',
      fontWeight: $fontWeight.regular,
      lineHeight: '19.07px',
    },
    '& .MuiFormControlLabel-label.Mui-disabled': {
      color: $colors.mHubGray2,
    },
  },
  switchLongLabel: {
    width: '70px',
  },
  switchShortLabel: {
    width: '56px',
  },
  subtitle: {
    ...theme.typography.h2,
    color: $colors.mHubSpace,
  },
  textfield: {
    paddingBottom: '24px',
  },
  title: {
    ...theme.typography.subtitle1,
    color: $colors.mHubSlate,
  },
  tooltip: {
    backgroundColor: $colors.mHubSlate,
    fontSize: '10px',
    fontFamily: $fontFamily.openSans,
    fontWeight: $fontWeight.regular,
  },
  // Form Control
  formControlSpaceB2Regular: {
    height: '100%',
    '& .MuiTypography-root': {
      color: $colors.mHubSlate,
      fontFamily: $fontFamily.openSans,
      fontWeight: $fontWeight.regular,
      fontSize: '12px',
      lineHeight: '16.34px',
    },
  },
  formControlSpaceB1Regular: {
    height: '100%',
    '& .MuiTypography-root': {
      color: $colors.mHubSlate,
      fontFamily: $fontFamily.openSans,
      fontWeight: $fontWeight.regular,
      fontSize: '14px',
      lineHeight: '19.07px',
    },
  },
  // Gray1
  gray1S2semiBold: {
    color: $colors.mHubGray1,
    fontFamily: $fontFamily.openSans,
    fontWeight: $fontWeight.semiBold,
    fontSize: '12px',
    lineHeight: '16.34px',
  },
  gray1B1Regular: {
    color: $colors.mHubGray1,
    fontFamily: $fontFamily.openSans,
    fontWeight: $fontWeight.regular,
    fontSize: '14px',
    lineHeight: '19.07px',
  },
  gray1B2Regular: {
    color: $colors.mHubGray1,
    fontFamily: $fontFamily.openSans,
    fontWeight: $fontWeight.regular,
    fontSize: '12px',
    lineHeight: '16.34px',
  },
  // Space
  spaceH1semiBold: {
    color: $colors.mHubSpace,
    fontFamily: $fontFamily.montserrat,
    fontWeight: $fontWeight.semiBold,
    fontSize: '24px',
    lineHeight: '19.26px',
  },
  spaceH2semiBold: {
    color: $colors.mHubSpace,
    fontFamily: $fontFamily.montserrat,
    fontWeight: $fontWeight.semiBold,
    fontSize: '22px',
    lineHeight: '26.82px',
  },
  spaceH4semiBold: {
    color: $colors.mHubSpace,
    fontFamily: $fontFamily.montserrat,
    fontWeight: $fontWeight.semiBold,
    fontSize: '19px',
    lineHeight: '23.16px',
  },
  spaceH5semiBold: {
    color: $colors.mHubSpace,
    fontFamily: $fontFamily.montserrat,
    fontWeight: $fontWeight.semiBold,
    fontSize: '17px',
    lineHeight: '20.72px',
  },
  spaceH6semiBold: {
    color: $colors.mHubSpace,
    fontFamily: $fontFamily.montserrat,
    fontWeight: $fontWeight.semiBold,
    fontSize: '15px',
    lineHeight: '18.29px',
  },
  spaceB1regular: {
    color: $colors.mHubSpace,
    fontFamily: $fontFamily.openSans,
    fontWeight: $fontWeight.regular,
    fontSize: '14px',
    lineHeight: '19.07px',
  },
  spaceS1semiBold: {
    color: $colors.mHubSpace,
    fontFamily: $fontFamily.openSans,
    fontWeight: $fontWeight.semiBold,
    fontSize: '14px',
    lineHeight: '19.07px',
  },
  spaceS2semiBold: {
    color: $colors.mHubSpace,
    fontFamily: $fontFamily.openSans,
    fontWeight: $fontWeight.semiBold,
    fontSize: '12px',
    lineHeight: '16.34px',
  },
  spaceS2regular: {
    color: $colors.mHubSpace,
    fontFamily: $fontFamily.openSans,
    fontWeight: $fontWeight.regular,
    fontSize: '12px',
    lineHeight: '16.34px',
  },
  // Slate
  slateH6semiBold: {
    color: $colors.mHubSlate,
    fontFamily: $fontFamily.montserrat,
    fontWeight: $fontWeight.semiBold,
    fontSize: '15px',
    lineHeight: '19.07px',
  },
  slateB1regular: {
    color: $colors.mHubSlate,
    fontFamily: $fontFamily.openSans,
    fontWeight: $fontWeight.regular,
    fontSize: '14px',
    lineHeight: '19.07px',
  },
  slateB2regular: {
    color: $colors.mHubSlate,
    fontFamily: $fontFamily.openSans,
    fontWeight: $fontWeight.regular,
    fontSize: '12px',
    lineHeight: '16.34px',
  },
  slateS1semiBold: {
    color: $colors.mHubSlate,
    fontFamily: $fontFamily.openSans,
    fontWeight: $fontWeight.semiBold,
    fontSize: '14px',
    lineHeight: '19.07px',
  },
  slateS2semiBold: {
    color: $colors.mHubSlate,
    fontFamily: $fontFamily.openSans,
    fontWeight: $fontWeight.semiBold,
    fontSize: '12px',
    lineHeight: '16.34px',
  },
  slateCaptionRegular: {
    color: $colors.mHubSlate,
    fontFamily: $fontFamily.openSans,
    fontWeight: $fontWeight.regular,
    fontSize: '10px',
    lineHeight: '13.62px',
  },
  slateCaptionSemibold: {
    color: $colors.mHubSlate,
    fontFamily: $fontFamily.openSans,
    fontWeight: $fontWeight.semiBold,
    fontSize: '10px',
    lineHeight: '13.62px',
  },
}));
