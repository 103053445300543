import { createStyles, makeStyles } from '@material-ui/core/styles';
import { $colors } from '../../styles/variables';
import { MHubIconProps } from './MHubIcon.types';

export default makeStyles(() => createStyles({
  badge__badge: {
    backgroundColor: `${$colors.mHubLightBlue} !important`,
  },
  iconBaseStyle: {
    width: (props: Pick<MHubIconProps, 'size'>) => props.size || 24,
    height: (props: Pick<MHubIconProps, 'size'>) => props.size || 24,
    cursor: 'pointer',
  },
}));
