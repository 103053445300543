export const NEW = 'new';
export const RESERVED = 'reserved';
export const BOOKED = 'booked';
export const SOLD = 'sold_spa_signed';
export const DELIST = 'delist';
export const DEVELOPER_RESERVED = 'developer_reserved';
export const DEVELOPER_BOOKED = 'developer_booked';
export const DEVELOPER_SOLD = 'developer_sold';

export const unitStatuses = [
  { text: 'Available', value: NEW },
  { text: 'Reserved', value: RESERVED },
  { text: 'Booked', value: BOOKED },
  { text: 'Sold', value: SOLD },
  { text: 'Delisted', value: DELIST },
];

export const UNIT_STATUSES_HQ_REAL = [
  { text: 'Available', value: 'new', name: 'new' },
  { text: 'Reserved', value: 'reserved', name: 'reserved' },
  { text: 'Booked', value: 'booked', name: 'booked' },
  { text: 'Sold', value: 'sold_spa_signed', name: 'sold_spa_signed' },
  { text: 'Delisted', value: 'delist', name: 'delist' },
  { text: 'Reserved outside MHub', value: 'developer_reserved', name: 'developer_reserved' },
  { text: 'Booked outside MHub', value: 'developer_booked', name: 'developer_booked' },
  { text: 'Sold outside MHub', value: 'developer_sold', name: 'developer_sold' },
];
