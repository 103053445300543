declare global {
  namespace NodeJS {
    interface ProcessEnv {
      REACT_APP_RELEASE_STAGE: string;
      REACT_APP_COGNITO_APP_CLIENT_ID: string;
      REACT_APP_COGNITO_COOKIE_DOMAIN: string;
      REACT_APP_COGNITO_COOKIE_SECURE?: '0' | '1';
      REACT_APP_COGNITO_IDENTITY_POOL_ID: string;
      REACT_APP_COGNITO_REGION: string;
      REACT_APP_COGNITO_USER_POOL_ID: string;
      REACT_APP_LOGIN_URL: string;
      REACT_APP_MHUB_API_URL: string;
      REACT_APP_SALES_CHART_URL: string;
      REACT_APP_PROJECT_LAMBDA: string;
      REACT_APP_COMPANIES_LAMBDA: string;
      REACT_APP_LAWYERS_LAMBDA: string;
      REACT_APP_BOOKING_LAMBDA: string;
      REACT_APP_S3_SITEPLAN_BUCKET: string;
      REACT_APP_S3_SITEPLAN_REGION: string;
      REACT_APP_MHUB_USER_URL: string;
    }
  }

  // interface Window {
  //   zE: (arg0?: string, arg1?: string) => void;
  //   zESettings: object;
  // }
}

export const setupAmplifyConfig = () => {
  const {
    // REACT_APP_RELEASE_STAGE: releaseStage,
    REACT_APP_COGNITO_APP_CLIENT_ID: appClientId,
    REACT_APP_COGNITO_COOKIE_DOMAIN: cookieDomain,
    REACT_APP_COGNITO_IDENTITY_POOL_ID: identityPoolId,
    REACT_APP_COGNITO_REGION: region,
    REACT_APP_COGNITO_USER_POOL_ID: userPoolId,
    REACT_APP_COGNITO_COOKIE_SECURE: cookieSecure = '0',
    REACT_APP_PROJECT_LAMBDA: projectLambda,
    REACT_APP_COMPANIES_LAMBDA: companiesLambda,
    REACT_APP_LAWYERS_LAMBDA: lawyersLambda,
    REACT_APP_MORTGAGE_LAMBDA: mortgageLambda,
    REACT_APP_TEMPLATE_LAMBDA: templateLambda,
    REACT_APP_BOOKING_LAMBDA: bookingLambda,
    REACT_APP_MHUB_USER_URL: userLambda,
  } = process.env;
  return {
    Auth: {
      cookieStorage: {
        domain: cookieDomain,
        secure: cookieSecure,
      },
      identityPoolId,
      mandatorySignIn: true,
      region,
      userPoolId,
      userPoolWebClientId: appClientId,
    },
    API: {
      endpoints: [
        {
          name: 'company',
          endpoint: companiesLambda,
          region,
        },
        {
          name: 'project',
          endpoint: projectLambda,
          region,
        },
        {
          name: 'lawyer',
          endpoint: lawyersLambda,
          region,
        },
        {
          name: 'template',
          endpoint: templateLambda,
          region,
        },
        {
          name: 'mortgage',
          endpoint: mortgageLambda,
          region,
        },
        {
          name: 'user',
          endpoint: userLambda,
          region,
        },
        {
          name: 'booking',
          endpoint: bookingLambda,
          region,
        },
      ],
    },
  };
};
