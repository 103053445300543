import {
  createContext,
  ReactNode,
  useContext,
  useState,
} from 'react';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import qs from 'query-string';
import { DefaultAPIResponse } from 'src/models';
import { isError } from 'src/utils';
import { invokeLambda } from 'src/utils/api';
import { isAWSError } from 'src/utils/aws';
import CompanyBranch, { CompanyBranchQuery } from '../models/companyBranch';

interface CompanyBranchContext {
  agencyBranches: CompanyBranch[];
  companyBranches: CompanyBranch[];
  companyBranch: CompanyBranch;
  companyBranchesError?: string;
  companyBranchesLoading: boolean;
  listCompanyBranches: (query?: CompanyBranchQuery, companyID?: string) => Promise<DefaultAPIResponse>;
  findCompanyBranch: (id: string, companyID?: string) => Promise<DefaultAPIResponse>;
}

const companyBranchContext = createContext<CompanyBranchContext>({
  agencyBranches: [],
  companyBranches: [],
  companyBranch: {} as CompanyBranch,
  companyBranchesError: '',
  companyBranchesLoading: false,
  listCompanyBranches: () => Promise.resolve({} as DefaultAPIResponse),
  findCompanyBranch: () => Promise.resolve({} as DefaultAPIResponse),
});

export function useProvideCompanyBranch(): CompanyBranchContext {
  const { companyID } = useParams<{ companyID: string }>();
  const [agencyBranches, setAgencyBranches] = useState<CompanyBranch[]>([]);
  const [companyBranches, setCompanyBranches] = useState<CompanyBranch[]>([]);
  const [companyBranch, setCompanyBranch] = useState<CompanyBranch>({} as CompanyBranch);
  const [companyBranchesError, setCompanyBranchesError] = useState<string>();
  const [companyBranchesLoading, setCompanyBranchesLoading] = useState(false);

  const handleError = (err: any) => {
    let errMsg = 'An error occurred';
    if (isAWSError(err)) {
      errMsg = err.response?.data.error_message || errMsg;
      setCompanyBranchesError(err.message);
    } else if (isError(err)) {
      errMsg = err.message;
      setCompanyBranchesError(err.message);
    } else {
      setCompanyBranchesError(errMsg);
    }
    return errMsg;
  };

  /**
   * List of company branches
   */
  const listCompanyBranches = async (pq = {}, cID?: string): Promise<DefaultAPIResponse> => {
    setCompanyBranchesLoading(true);
    const xcompanyID = cID ?? companyID;
    try {
      const path = '/branches';
      const query: CompanyBranchQuery = _.isEmpty(pq) ? { limit: '-1', companyID: xcompanyID } : pq;
      const q = qs.parse(qs.stringify(query));
      const res = await invokeLambda({
        name: 'company',
        method: 'get',
        path,
        query: q,
        header: {
          'x-company-id': xcompanyID,
        },
      });
      if (res && res.items) {
        if (query && query.isExternal) {
          setAgencyBranches(res.items);
        } else {
          setCompanyBranches(res.items);
        }
        setCompanyBranchesLoading(false);
        return {
          body: res.items,
          status: 'success',
        };
      }
    } catch (err) {
      setCompanyBranchesLoading(false);
      const errMsg = handleError(err);
      return {
        message: errMsg,
        status: 'rejected',
      };
    }
    setCompanyBranchesLoading(false);
    return {
      status: 'success',
    };
  };

  /**
   * Find company branch
   */
  const findCompanyBranch = async (id: string, cID?: string): Promise<DefaultAPIResponse> => {
    setCompanyBranchesLoading(true);
    const xcompanyID = cID ?? companyID;
    try {
      const path = `/branches/${id}`;
      const res = await invokeLambda({
        name: 'company',
        method: 'get',
        path,
        header: {
          'x-company-id': xcompanyID,
        },
      });
      if (res) {
        setCompanyBranch(res);
        return {
          body: res,
          status: 'success',
        };
      }
    } catch (err) {
      setCompanyBranchesLoading(false);
      const errMsg = handleError(err);
      return {
        message: errMsg,
        status: 'rejected',
      };
    }
    setCompanyBranchesLoading(false);
    return {
      status: 'success',
    };
  };

  return {
    agencyBranches,
    companyBranches,
    companyBranch,
    companyBranchesError,
    companyBranchesLoading,
    listCompanyBranches,
    findCompanyBranch,
  };
}

export const useCompanyBranchContext = () => useContext(companyBranchContext);

export function ProvideCompanyBranch({
  children,
}: {
  children: ReactNode,
}) {
  const companyBranch = useProvideCompanyBranch();
  return (
    <companyBranchContext.Provider value={companyBranch}>
      {children}
    </companyBranchContext.Provider>
  );
}
