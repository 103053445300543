import {
  createContext,
  ReactNode,
  useContext,
  useState,
} from 'react';
import { Color } from '@material-ui/lab/Alert';

const SUCCESS_ALERT = 'success';
const ERROR_ALERT = 'error';
const INFO_ALERT = 'info';
const WARNING_ALERT = 'warning';

interface AlertContext {
  /**
   * Severity state. Will set alert colour.
   */
  severity: Color;

  /**
   * Title state. Will show as message's title in alert.
   */
  title: string;

  /**
   * Message state. Will show as message in alert.
   */
  message: string;

  /**
   * Indicator to indicate if alert is shown or hidden
   */
  alert: boolean;

  /**
   * Full width page allows original snackbar position
   */
  fullWidthPage: boolean;

  /**
   * Drawer alert show within the drawer
   */
  isDrawerAlert: boolean;

  /**
   * Helper method to set alert message & severity via `AlertMessage`.
   * If `show=true`, alert will auto prompt.
   */
  setAlert: (message: AlertMessage, show?: boolean) => void;

  /**
   * Helper method to set `alert` to `true`.
   */
  showAlert: () => void;

  // Helper method to show error alert
  showErrorAlert: (message: AlertMessage) => void;

  // Helper method to show success alert
  showSuccessAlert: (message: AlertMessage) => void;

  // Helper method to show warning alert
  showWarningAlert: (message: AlertMessage) => void;

  /**
   * Helper method to set `alert` to `false`.
   */
  hideAlert: () => void;
}

export interface AlertMessage {
  severity?: Color;
  title?: string;
  message: string;
  fullWidthPage?: boolean;
  isDrawerAlert?: boolean;
}

const alertContext = createContext<AlertContext>({
  severity: INFO_ALERT,
  title: '',
  message: '',
  alert: false,
  fullWidthPage: false,
  isDrawerAlert: false,
  setAlert: () => { },
  showAlert: () => { },
  showErrorAlert: () => {},
  showSuccessAlert: () => {},
  showWarningAlert: () => {},
  hideAlert: () => { },
});

export function useProvideAlert(): AlertContext {
  const [severity, setSeverity] = useState<Color>(INFO_ALERT);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [alert, setShowAlert] = useState(false);
  const [fullWidthPage, setFullWidthPage] = useState(false);
  const [isDrawerAlert, setIsDrawerAlert] = useState(false);

  const showAlert = () => {
    setShowAlert(true);
  };

  const setAlert = (m: AlertMessage, show?: boolean) => {
    setSeverity(m.severity || INFO_ALERT);
    setMessage(m.message);
    setFullWidthPage(m?.fullWidthPage || false);
    setIsDrawerAlert(m?.isDrawerAlert || false);
    if (m.title) {
      setTitle(m.title);
    } else {
      setTitle('');
    }
    if (show) {
      showAlert();
    }
  };

  const showErrorAlert = (m: AlertMessage) => {
    setSeverity(ERROR_ALERT);
    setMessage(m.message);
    setTitle(m.title || '');
    setFullWidthPage(m?.fullWidthPage || false);
    setIsDrawerAlert(m?.isDrawerAlert || false);
    showAlert();
  };

  const showSuccessAlert = (m: AlertMessage) => {
    setSeverity(SUCCESS_ALERT);
    setMessage(m.message);
    setTitle(m.title || '');
    setFullWidthPage(m?.fullWidthPage || false);
    setIsDrawerAlert(m?.isDrawerAlert || false);
    showAlert();
  };

  const showWarningAlert = (m: AlertMessage) => {
    setSeverity(WARNING_ALERT);
    setMessage(m.message);
    setTitle(m.title || '');
    setFullWidthPage(m?.fullWidthPage || false);
    setIsDrawerAlert(m?.isDrawerAlert || false);
    showAlert();
  };

  const hideAlert = () => {
    setShowAlert(false);
  };

  return {
    severity,
    title,
    message,
    alert,
    setAlert,
    showAlert,
    fullWidthPage,
    isDrawerAlert,
    showErrorAlert,
    showSuccessAlert,
    showWarningAlert,
    hideAlert,
  };
}

export const useAlert = () => useContext(alertContext);

export function ProvideAlert({
  children,
}: {
  children: ReactNode,
}) {
  const alert = useProvideAlert();
  return (
    <alertContext.Provider value={alert}>
      {children}
    </alertContext.Provider>
  );
}
