import { lazy, Suspense } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { isProd } from 'src/utils/url';
import AppSnackBar from '../AppSnackbar/AppSnackbar';
import RouteAuthenticated from './RouteAuthenticated';
import routes from './routes';

export { routes };

const Reports = lazy(() => import('../../apps/reports/Reports'));
const ProjectEdit = lazy(() => import('../../pages/Project/Settings'));
const ProjectDetails = lazy(() => import('../../pages/Project/Overview'));
const ProjectsList = lazy(() => import('../../pages/Projects/List'));
const ProjectUnitDetails = lazy(() => import('../../pages/Project/Unit'));
const NewProject = lazy(() => import('../../pages/Projects/New'));
const ProjectPackagePricingsImport = lazy(() => import('../../pages/Project/PackagePricings/PackagePricingsImport'));
const PackagePricingJobListing = lazy(() => import('../../pages/Project/PackagePricings/PackagePricingJobListing'));
const UnitsBulkEdit = lazy(() => import('../../pages/Project/Units/UnitsBulkEdit'));
const UnitsCompare = lazy(() => import('../../pages/Project/Units/UnitsCompare'));
const UnitsImport = lazy(() => import('../../pages/Project/Units/UnitsImport'));
const BookingList = lazy(() => import('../../pages/Bookings'));
const BookingsManage = lazy(() => import('../../pages/Bookings/BookingsManage'));
const NotFound = lazy(() => import('../../pages/NotFound/NotFound'));
const ProjectPackagePricings = lazy(() => import('../../pages/Project/PackagePricings'));
const ProjectUnitLayoutDetails = lazy(() => import('../../pages/Project/LayoutDetails'));
const BookingOverview = lazy(() => import('../../pages/Bookings/Overview'));
const AccessDenied = lazy(() => import('../../pages/AccessDenied/AccessDenied'));
const ConnectionError = lazy(() => import('../../pages/ConnectionError/ConnectionError'));
const PackageFeeJobListing = lazy(() => import('../../pages/Project/PackageFees/PackageFeeJobListing'));
const ProjectFeePackages = lazy(() => import('../../pages/Project/PackageFees'));

export default function Routes() {
  return (
    <>
      <AppSnackBar />
      <Suspense fallback={<></>}>
        <Switch>
          {
            !isProd && (<RouteAuthenticated exact path={routes.bookingsManage(':companyID')} component={BookingsManage} />)
          }
          <RouteAuthenticated exact path={routes.projectsNew(':companyID')} component={NewProject} />
          <RouteAuthenticated exact path={routes.projectsUpdate(':companyID', ':id')} component={ProjectEdit} />
          <RouteAuthenticated path="/analytics/:category/:page" component={Reports} />
          <RouteAuthenticated path="/access_denied" component={AccessDenied} />
          <RouteAuthenticated path="/connection_error" component={ConnectionError} />
          <Redirect exact from="/" to="/analytics/sales/overview" />
          <Redirect exact from="/analytics" to="/analytics/sales/overview" />
          <RouteAuthenticated exact path={routes.projectPackagePricingJobListing(':companyID', ':id')} component={PackagePricingJobListing} />
          <RouteAuthenticated exact path={routes.projectPricingPackageImport(':companyID', ':id')} component={ProjectPackagePricingsImport} />
          <RouteAuthenticated exact path={routes.projectPackagePricings(':companyID', ':id', ':packageID')} component={ProjectPackagePricings} />
          <RouteAuthenticated exact path={routes.projectFeePackageJobListing(':companyID', ':id')} component={PackageFeeJobListing} />
          <RouteAuthenticated exact path={routes.projectFeePackages(':companyID', ':id', ':packageID')} component={ProjectFeePackages} />
          {
            !isProd && (
              <>
                <RouteAuthenticated exact path={routes.projectsDetails(':companyID', ':id')} component={ProjectDetails} />
                <RouteAuthenticated exact path={routes.projectsList(':companyID')} component={ProjectsList} />
                <RouteAuthenticated exact path={routes.unitsBulkEdit(':companyID', ':id')} component={UnitsBulkEdit} />
                <RouteAuthenticated exact path={routes.unitsCompare(':companyID', ':id')} component={UnitsCompare} />
                <RouteAuthenticated exact path={routes.unitsImport(':companyID', ':id')} component={UnitsImport} />
                <RouteAuthenticated exact path={routes.projectUnitLayoutDetails(':companyID', ':id', ':layoutID')} component={ProjectUnitLayoutDetails} />
                <RouteAuthenticated exact path={routes.projectUnitDetails(':companyID', ':id', ':unitID')} component={ProjectUnitDetails} />
                <RouteAuthenticated exact path={routes.bookingList(':companyID')} component={BookingList} />
                <RouteAuthenticated exact path={routes.bookingOverview(':companyID', ':bookingID')} component={BookingOverview} />
              </>
            )
          }
          <RouteAuthenticated path="*" component={NotFound} />
        </Switch>
      </Suspense>
    </>
  );
}
