const routes = {
  home: '/',
  projectsList: (companyID: string): string => `/c/${companyID}/projects`,
  projectsNew: (companyID: string): string => `/c/${companyID}/projects/new`,
  projectsDetails: (companyID: string, projectID: string): string => `/c/${companyID}/projects/${projectID}`,
  projectsUpdate: (companyID: string, projectID: string): string => `/c/${companyID}/projects/${projectID}/edit`,
  projectUnitLayoutDetails: (companyID: string, projectID: string, layoutID: string): string => `/c/${companyID}/projects/${projectID}/layouts/${layoutID}`,
  projectUnitDetails: (companyID: string, projectID: string, unitID: string): string => `/c/${companyID}/projects/${projectID}/units/${unitID}`,
  projectPackagePricings: (companyID: string, projectID: string, packageID: string): string => `/c/${companyID}/projects/${projectID}/package_pricings/${packageID}`,
  projectPricingPackageImport: (companyID: string, projectID: string): string => `/c/${companyID}/projects/${projectID}/import/package_pricings`,
  projectPackagePricingJobListing: (companyID: string, projectID: string): string => `/c/${companyID}/projects/${projectID}/package_pricings/job_listings`,
  projectFeePackageJobListing: (companyID: string, projectID: string): string => `/c/${companyID}/projects/${projectID}/additional_fees/job_listings`,
  projectFeePackages: (companyID: string, projectID: string, packageID: string): string => `/c/${companyID}/projects/${projectID}/additional_fees/${packageID}`,
  unitsBulkEdit: (companyID: string, projectID: string): string => `/c/${companyID}/projects/${projectID}/bulk_edit/units`,
  unitsCompare: (companyID: string, projectID: string): string => `/c/${companyID}/projects/${projectID}/compare/units`,
  unitsImport: (companyID: string, projectID: string): string => `/c/${companyID}/projects/${projectID}/import/units`,
  bookingList: (companyID: string): string => `/c/${companyID}/bookings`,
  bookingsManage: (companyID: string): string => `/c/${companyID}/bookings/manage`,
  bookingOverview: (companyID: string, bookingID: string): string => `/c/${companyID}/bookings/${bookingID}`,
};

export default routes;
