import {
  Snackbar, Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { AlertTitle } from '@material-ui/lab';
import { useAlert } from 'src/hooks/useAlert';
import { formStyles } from 'src/styles/form';
import AppAlert from '../AppAlert';
import MHubIcon from '../MHubIcon';
import { useStyles } from './AppSnackbar.styles';

export default function AppSnackBar() {
  const classes = useStyles();
  const formClasses = formStyles();
  const {
    severity,
    title,
    message,
    alert,
    hideAlert,
    fullWidthPage,
    isDrawerAlert,
  } = useAlert();

  const getIcon = () => {
    if (severity === 'error') {
      return (
        <MHubIcon
          className={classes.alertIcon}
          name="alert"
          size="20px"
        />
      );
    }
    if (severity === 'success') {
      return (
        <MHubIcon
          className={classes.checkmarkFilledIcon}
          name="checkmarkFilled"
          size="19.67px"
        />
      );
    }
    return null;
  };

  return (
    <Snackbar
      open={alert}
      autoHideDuration={6000}
      onClose={hideAlert}
      classes={{
        anchorOriginBottomLeft: fullWidthPage ? classes.fullWidthPosition : classes.bottomLeft,
        anchorOriginBottomRight: isDrawerAlert ? classes.drawerAlert : '',
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: isDrawerAlert ? 'right' : 'left',
      }}
    >
      <AppAlert
        onClose={hideAlert}
        icon={getIcon()}
        severity={severity}
      >
        {
          title ? (
            <AlertTitle>{title}</AlertTitle>
          ) : ''
        }
        <Typography
          className={clsx(
            formClasses.spaceS2regular,
            classes.message,
            isDrawerAlert ? 'breakLine' : '',
          )}
        >
          {message}
        </Typography>
      </AppAlert>
    </Snackbar>
  );
}
