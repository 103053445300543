import {
  createStyles, makeStyles, Theme, darken,
} from '@material-ui/core/styles';
import {
  $fontFamily,
  $fontSize,
  $fontWeight,
  $colors,
  DESK_DRAWER_WIDTH,
  DESK_DRAWER_SUBPANEL_WIDTH,
  DESK_DRAWER_ICON_WIDTH,
  DESK_DRAWER_ICON_AND_CLOSE_WIDTH,
} from '../../styles/variables';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  betaChip: {
    background: $colors.mHubTeal,
    color: $colors.mHubWhite,
    marginLeft: '5px',
    height: '20px',
    '& .MuiChip-label': {
      fontFamily: $fontFamily.openSans,
      fontWeight: $fontWeight.semiBold,
      fontSize: $fontSize['10px'],
      lineHeight: '13.62px',
      padding: '3px 8px',
    },
  },
  root: {
    minHeight: '50px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 1.5rem',
      '& > div': {
        flexGrow: 1,
      },
      '&.expand': {
        padding: '0 0.5rem',
      },
    },
    position: 'fixed',
    padding: '0.5rem 2rem',
    border: 0,
    borderBottom: `1px solid ${$colors.mHubGray3}`,
  },
  navigationsContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > div:not(:second-child)': {
      padding: '0 12px',
    },
  },
  menu: {
    width: '200px',
    padding: 0,
    '& li': {
      height: '50px',
    },
  },
  appBar: {
    backgroundColor: `${$colors.mHubWhite} !important`,
    boxShadow: 'unset !important',
    ...theme.mixins.toolbar,
    color: `${$colors.mHubSlate} !important`,
    border: `1px solid ${$colors.mHubGray3} !important`,
  },
  menuButton: {
    float: 'right',
    color: `${$colors.mHubSlate} !important`,
    height: '100%',
  },
  toolbarHeight: {
    ...theme.mixins.toolbar,
  },
  imgSize: {
    margin: 'auto auto',
  },
  menuStyle: {
    height: '100%',
    overflow: 'hidden',
    backgroundColor: $colors.mHubGray3,
  },
  drawerHeader: {
    padding: '15px 15px 15px 25px',
    letterSpacing: 'unset',
  },
  closeIcon: {
    float: 'right',
    cursor: 'pointer',
    color: $colors.mHubSlate,
    height: '24px',
    width: '24px',
    borderRadius: '50%',
    border: `1px solid ${$colors.mHubGray2}`,
    position: 'fixed',
    background: $colors.mHubWhite,
    '&:hover': {
      background: $colors.mHubTeal,
      borderColor: 'transparent',
      color: $colors.mHubWhite,
    },
  },
  drawerTitle: {
    '& span': {
      fontSize: '14px',
      color: $colors.mHubSpace,
      fontWeight: $fontWeight.bold,
      fontFamily: $fontFamily,
      userSelect: 'none',
      textTransform: 'none',
    },
  },
  drawer__paperAnchorLeft: {
    width: `${DESK_DRAWER_WIDTH}px`,
    border: 'transparent !important',
  },
  menuBox: {
    height: '100%',
    width: `${DESK_DRAWER_SUBPANEL_WIDTH}px`,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    '& #presentation': {
      justifyContent: 'normal',
    },
  },
  iconBox: {
    height: '100%',
    width: `${DESK_DRAWER_ICON_WIDTH}px`,
    backgroundColor: $colors.mHubTeal,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  upper: {
    justifyContent: 'normal',
    '& .st0': {
      fill: $colors.mHubWhite,
    },
  },
  itemText: {
    padding: '10px 15px',
    margin: '2px 0px !important',
    color: $colors.mHubSlate,
    '&:hover': {
      backgroundColor: $colors.mHubGray2b,
      color: $colors.mHubSpace,
      borderRadius: '5px',
    },
  },
  itemTextActive: {
    backgroundColor: $colors.mHubGray2b,
    margin: '2px 0px !important',
    color: $colors.mHubSpace,
    padding: '10px 15px',
    borderRadius: '5px',
  },
  list: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
    cursor: 'pointer',
    userSelect: 'none',
    '& span': {
      fontSize: '14px',
      fontFamily: $fontFamily,
    },
  },
  companyName: {
    borderRight: `1px solid ${$colors.mHubGray2}`,
    marginRight: '10px !important',
    paddingRight: '24px !important',
    cursor: 'pointer',
  },
  singleLink: {
    textDecoration: 'none',
    margin: '0px 10px !important',
    cursor: 'pointer',
    '& p': {
      color: $colors.mHubTeal,
    },
  },
  sideNavIcon: {
    color: $colors.mHubSpace,
    width: '100%',
    padding: '10px !important',
    borderRadius: 'unset !important',
    height: '50px',
    '&:hover, :focus': {
      backgroundColor: `${darken($colors.mHubTeal, 0.25)} !important`,
      width: '100%',
      '& span': {
        color: $colors.mHubTeal,
      },
    },
  },
  sideNavIconActive: {
    padding: '10px !important',
    borderRadius: 'unset !important',
    backgroundColor: `${darken($colors.mHubTeal, 0.25)} !important`,
    width: '100%',
    height: '50px',
    '&:hover': {
      backgroundColor: `${darken($colors.mHubTeal, 0.25)} !important`,
    },
    '& span': {
      color: $colors.mHubWhite,
    },
  },
  drawerOpen: {
    width: `${DESK_DRAWER_WIDTH}px`,
    transition: `${theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    })} !important`,
    '&#side-menu > div:first-child': {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflow: 'hidden',
    width: `calc( ${DESK_DRAWER_WIDTH}px - ${DESK_DRAWER_ICON_AND_CLOSE_WIDTH}px)`,
    '&#side-menu > div:first-child': {
      width: `calc( ${DESK_DRAWER_WIDTH}px - ${DESK_DRAWER_SUBPANEL_WIDTH}px)`,
    },
    backgroundColor: $colors.mHubGray3,
  },
  headerStyle: {
    '& p': {
      fontFamily: $fontFamily.montserrat,
      fontSize: '16px',
    },
  },
  tooltip__tooltip: {
    fontSize: `${'12px'} !important`,
    fontFamily: $fontFamily.openSans,
  },
  tooltip__tooltipPlacementRight: {
    margin: '0 5px !important',
  },
  anchorMenu: {
    '& li:first-child': {
      borderBottom: `1px solid ${$colors.mHubGray2}`,
      backgroundColor: 'unset !important',
      cursor: 'default',
    },
    '& li': {
      height: '50px',
      color: $colors.mHubSpace,
      fontFamily: $fontFamily.openSans,
      fontSize: '14px',
      '& svg': {
        marginRight: '15px',
        width: '24px',
        height: '24px',
        color: $colors.mHubSlate,
      },
    },
    '& .MuiMenu-paper': {
      width: '200px',
      top: '50px !important',
    },
  },
  nameText: {
    '& p': {
      color: $colors.mHubSpace,
      fontFamily: $fontFamily.openSans,
      fontSize: '14px',
      fontWeight: $fontWeight.semiBold,
    },
  },
  version: {
    justifyContent: 'center',
    '& p': {
      color: $colors.mHubSpace,
      fontSize: '10px',
    },
  },
  closeContainer: {
    position: 'relative',
    zIndex: 1201,
    left: '-14px',
    bottom: '25px',
    height: '100vh',
    top: '78px',
  },
  footer: {
    paddingLeft: theme.spacing(2.5),
    color: $colors.mHubGray1,
    fontSize: '12px',
    fontFamily: $fontFamily.openSans,
  },
  logo: {
    display: 'flex',
    borderRight: `1px solid ${$colors.mHubGray2}`,
    paddingRight: '1rem',
  },
  ul: {
    listStyleType: 'none',
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    margin: 0,
    fontFamily: 'Montserrat, sans-serif',
  },
  generalLink: {
    fontSize: '15.4px',
    marginLeft: '1.5rem',
    color: $colors.mHubSlate,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.primary.main,
      paddingBottom: '4px',
      borderBottom: `1.3px solid ${theme.palette.primary.main}`,
    },
  },
  activeLink: {
    color: theme.palette.primary.main,
    paddingBottom: '4px',
    borderBottom: `1.3px solid ${theme.palette.primary.main}`,
  },
  helpButton: {
    float: 'right',
    color: `${$colors.mHubSlate} !important`,
    height: '100%',
    '& > .MuiIconButton-label': {
      paddingTop: '1px',
    },
  },
  buttonContainer: {
    width: '100%',
    '& > .MuiTableCell-body': {
      borderBottom: 'none',
      padding: '0',
      width: '100%',
    },
  },
  anchorBadgeMenu: {
    '& li:first-child': {
      borderBottom: `1px solid ${$colors.mHubGray2}`,
      backgroundColor: 'unset !important',
      cursor: 'default',
    },
    '& li': {
      height: '50px',
      color: $colors.mHubSpace,
      fontFamily: $fontFamily.openSans,
      fontSize: '14px',
      '& .MuiBadge-root': {
        marginRight: '15px',
      },
      '& svg': {
        width: '24px',
        height: '24px',
        color: $colors.mHubSlate,
      },
    },
    '& .MuiMenu-paper': {
      width: '200px',
      top: '50px !important',
    },
  },
}));
