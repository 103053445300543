import { Component } from 'react';
import ReactGA4 from 'react-ga4';
import { Route } from 'react-router-dom';
import { useAuth } from './hooks/useAuth';

interface ILocation {
  pathname: string;
  search: string;
}

interface IConfig {
  location: ILocation;
  readonly userID: string;
  readonly authenticated: boolean;
}

class GoogleAnalytics extends Component<IConfig> {
  public componentDidMount = () => {
    const { location } = window;
    ReactGA4.initialize(`${process.env.REACT_APP_GA_TRACK_ID}`);
    this.logPageChange(
      location.pathname,
      location.search,
    );
  };

  public componentDidUpdate = ({ location: prevLocation }: any) => {
    const { location: { pathname, search } } = window;
    const isDifferentPathname = pathname !== prevLocation.pathname;
    const isDifferentSearch = search !== prevLocation.search;
    if (isDifferentPathname || isDifferentSearch) {
      this.logPageChange(pathname, search);
    }
  };

  logPageChange(pathname: string, search: string) {
    const page = pathname + search;
    const { location } = window;
    const { userID, authenticated } = this.props;
    if (authenticated) {
      ReactGA4.set({
        page,
        location: `${location.origin}${page}`,
        userId: userID,
      });
      ReactGA4.send({ hitType: 'pageview', page });
    }
  }

  render() {
    return null;
  }
}

const RouteTracker = () => {
  const auth = useAuth();

  return (
    <>
      {process.env.REACT_APP_RELEASE_STAGE === 'prod' && (
        <Route
          render={(props) => (
            <GoogleAnalytics
              {...props}
              userID={auth.userID}
              authenticated={auth.authenticated}
            />
          )}
        />
      )}
    </>
  );
};

export default {
  GoogleAnalytics,
  RouteTracker,
};
