export const SUPER_ADMIN = 'SUPER_ADMIN';
export const ADMIN_COMMUNITY = 'ADMIN_COMMUNITY';
export const ADMIN_BASIC = 'ADMIN_BASIC';
export const ADMIN = 'ADMIN';
export const ANALYTICS = 'ANALYTICS';
export const DEVELOPER_BRANCH_LEADER_SUPERVISOR_II = 'DEVELOPER_BRANCH_LEADER_SUPERVISOR_II';
export const DEVELOPER_BRANCH_LEADER_SUPERVISOR = 'DEVELOPER_BRANCH_LEADER_SUPERVISOR';
export const DEVELOPER_BRANCH_LEADER = 'DEVELOPER_BRANCH_LEADER';
export const DEVELOPER_SITEPLAN = 'DEVELOPER_SITEPLAN';
export const DEVELOPER_AGENT_LEADER = 'DEVELOPER_AGENT_LEADER';
export const DEVELOPER_AGENCY_ADMIN = 'DEVELOPER_AGENCY_ADMIN';
export const DEVELOPER_EXECUTIVE = 'DEVELOPER_EXECUTIVE';
export const DEVELOPER_HEADQUARTERS = 'DEVELOPER_HEADQUARTERS';
export const DEVELOPER_TEAM_LEADER = 'DEVELOPER_TEAM_LEADER';
