import { createMuiTheme, lighten, fade } from '@material-ui/core/styles';
import {
  $fontSize,
  $fontFamily,
  $fontWeight,
  $colors,
} from './variables';

const defaultTheme = createMuiTheme();

const globalTheme = (createMuiTheme as any)({
  palette: {
    primary: {
      main: '#49B3B1',
    },
    secondary: {
      main: '#0094B3',
    },
  },
  overrides: {
    MuiAppBar: {
      root: {
        zIndex: 1201,
      },
    },
    MuiOutlinedInput: {
      root: {
        backgroundColor: $colors.mHubWhite,
        '&$focused $notchedOutline': {
          borderColor: $colors.mHubTeal,
          borderWidth: '1px',
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: $colors.mHubTeal,
        },
        '&$disabled': {
          backgroundColor: $colors.mHubGray3,
        },
        '&$disabled $notchedOutline': {
          border: 'none',
        },
        '&&& $input::-webkit-input-placeholder': {
          color: $colors.mHubGray1,
          opacity: 1,
        },
      },
      notchedOutline: {
        borderColor: $colors.mHubGray2,
      },
      input: {
        color: $colors.mHubSpace,
        fontFamily: $fontFamily.openSans,
        fontSize: $fontSize['14px'],
        fontWeight: $fontWeight.regular,
        lineHeight: '19.07px',
        padding: '14px 19px 12px 19px',
        '& input::-webkit-input-placeholder': {
          color: $colors.mHubGray1,
          fontWeight: $fontWeight.regular,
          fontFamily: $fontFamily.openSans,
        },
        '&$disabled': {
          color: $colors.mHubGray1,
        },
      },
      multiline: {
        padding: '12px 14px',
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: $fontSize['14px'],
        minHeight: '48px',
      },
    },
    MuiListItem: {
      root: {
        color: `${$colors.mHubSpace} !important`,
      },
      divider: {
        borderBottom: `1px solid ${$colors.mHubGray3}`,
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: 0,
      },
      root: {
        fontSize: $fontSize['12px'],
        '&$error': {
          fontSize: $fontSize['10px'],
        },
      },
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: '0px',
      },
    },
    MuiFormControlLabel: {
      label: {
        '&$disabled': {
          color: $colors.mHubGray2,
        },
      },
    },
    MuiButton: {
      contained: {
        color: $colors.mHubWhite,
        fontFamily: $fontFamily.openSans,
        fontSize: $fontSize['14px'],
        fontWeight: $fontWeight.semiBold,
        boxShadow: 'unset',
        backgroundColor: $colors.mHubBlue,
        '&:hover': {
          backgroundColor: lighten($colors.mHubBlue, 0.2),
        },
        '&:focus': {
          backgroundColor: lighten($colors.mHubBlue, 0.2),
        },
        '&:focus:active': {
          backgroundColor: $colors.mHubBlue,
        },
        '&:hover:active': {
          backgroundColor: $colors.mHubBlue,
        },
        '&$disabled': {
          color: $colors.mHubGray2,
          backgroundColor: $colors.mHubGray3,
        },
      },
      outlined: {
        fontFamily: $fontFamily.openSans,
        border: `1px solid ${$colors.mHubGray2}`,
        '&$disabled': {
          color: $colors.mHubGray2,
        },
      },
      root: {
        textTransform: 'none',
        '&$disabled': {
          color: $colors.mHubGray2,
        },
      },
    },
    MuiTypography: {
      root: {
        color: $colors.mHubSpace,
      },
      colorPrimary: {
        color: $colors.mHubSlate,
      },
      colorSecondary: {
        color: $colors.mHubTeal,
      },
      colorTextPrimary: {
        color: $colors.mHubGray1,
      },
      colorTextSecondary: {
        color: $colors.mHubGray2,
      },
      colorError: {
        color: $colors.mHubPeach,
      },
    },
    MuiFab: {
      root: {
        boxShadow: 'unset',
      },
    },
    MuiMenu: {
      paper: {
        boxShadow: 'unset',
        border: `1px solid ${$colors.mHubGray2}`,
      },
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: $colors.mHubTeal,
      },
    },
    MuiTab: {
      root: {
        fontFamily: $fontFamily.openSans,
        fontSize: $fontSize['12px'],
        fontWeight: $fontWeight.semiBold,
        color: $colors.mHubSlate,
        borderBottom: `2px solid ${$colors.mHubGray3}`,
      },
      textColorPrimary: {
        '&$disabled': {
          color: $colors.mHubGray2,
        },
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        color: $colors.mHubGray2,
        '&$checked': {
          color: $colors.mHubMint,
        },
        '&$disabled': {
          color: $colors.mHubGray3,
        },
        '&:hover': {
          backgroundColor: fade($colors.mHubSpace, 0.04),
          color: $colors.mHubMint,
        },
      },
    },
    MuiRadio: {
      colorSecondary: {
        color: $colors.mHubGray2,
        '&$checked': {
          color: $colors.mHubTeal,
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
        '&$disabled': {
          color: $colors.mHubGray3,
        },
        '&:hover': {
          backgroundColor: 'transparent',
          color: $colors.mHubTeal,
        },
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
      icon: {
        top: 'calc(50% - 10px)',
      },
    },
    MuiTableCell: {
      head: {
        borderBottom: `1px solid ${$colors.mHubGray2}`,
      },
      body: {
        borderBottom: `1px solid ${$colors.mHubGray3}`,
      },
    },
    MuiAutocomplete: {
      inputRoot: {
        '&&[class*="MuiOutlinedInput-root"] $input': {
          padding: '3px',
        },
      },
      option: {
        '&[data-focus="true"]': {
          backgroundColor: $colors.mHubGray3,
        },
        '&[aria-selected="true"]': {
          backgroundColor: $colors.mHubGray3,
        },
      },
      popper: {
        margin: 0,
      },
      paper: {
        border: `1px solid ${$colors.mHubGray2}`,
        borderRadius: '4px',
        boxShadow: 'none',
        left: '1px',
        margin: 0,
        padding: 0,
        '& .MuiAutocomplete-listbox': {
          padding: 0,
          maxHeight: '220px',
        },
        '& .MuiAutocomplete-option': {
          borderRadius: '4px',
          overflow: 'hidden',
          padding: 0,
        },
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: $colors.mHubWhite,
      },
      toolbarBtnSelected: {
        color: $colors.mHubWhite,
      },
    },
    MuiPickersDay: {
      day: {
        '& p': {
          fontFamily: $fontFamily.openSans,
          fontSize: $fontSize['12px'],
          color: $colors.mHubSlate,
        },
        '&:hover': {
          fontWeight: $fontWeight.bold,
          border: '1px solid',
          borderColor: $colors.mHubTeal,
        },
      },
      daySelected: {
        borderRadius: '50%',
        backgroundColor: $colors.mHubTeal,
        '& p': {
          color: $colors.mHubWhite,
        },
        '&:hover': {
          backgroundColor: $colors.mHubTeal,
        },
      },
    },
    MuiSkeleton: {
      text: {
        transformOrigin: 0,
      },
    },
    MuiSvgIcon: {
      colorPrimary: {
        color: $colors.mHubTeal,
      },
      colorSecondary: {
        color: $colors.mHubSlate,
      },
      colorDisabled: {
        color: $colors.mHubGray2,
      },
    },
    MuiLink: {
      root: {
        color: $colors.mHubTeal,
      },
      underlineHover: {
        cursor: 'pointer',
        '&:hover': {
          color: $colors.mHubTealHover,
        },
      },
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: $colors.mHubTeal,
      },
      colorSecondary: {
        color: $colors.mHubGray2b,
      },
      circle: {
        strokeLinecap: 'round',
      },
    },
    MuiChip: {
      colorPrimary: {
        color: $colors.mHubSlate,
        backgroundColor: $colors.mHubGray3,
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        minHeight: 0,
        '&&[class*="Mui-expanded"]': {
          minHeight: 0,
        },
      },
      content: {
        margin: 0,
        '&&[class*="Mui-expanded"]': {
          margin: 0,
        },
      },
    },
    MuiDivider: {
      root: {
        borderTop: 'thin solid rgba(0, 0, 0, 0.12)',
        backgroundColor: undefined,
        height: undefined,
      },
    },
    MuiIconButton: {
      root: {
        '&$disabled': {
          color: $colors.mHubGray2,
        },
      },
    },
    MuiInputAdornment: {
      positionStart: {
        '& p': {
          color: $colors.mHubGray1,
        },
      },
    },
    MuiSnackbar: {
      anchorOriginBottomLeft: {
        bottom: '90px',
        left: '260px',
        '@media (min-width: 600px)': {
          bottom: '90px',
          left: '260px',
        },
      },
    },
    MuiAlert: {
      root: {
        minWidth: '344px',
        padding: '10px 16px',
      },
      action: {
        color: $colors.mHubSlate,
      },
      filledError: {
        backgroundColor: $colors.mHubRedBg,
      },
      filledSuccess: {
        backgroundColor: $colors.mHubMintBg,
      },
      icon: {
        marginTop: 'auto',
        marginBottom: 'auto',
      },
    },
    MuiAlertTitle: {
      root: {
        color: $colors.mHubSpace,
        fontFamily: $fontFamily.openSans,
        fontWeight: $fontWeight.semiBold,
        fontSize: '12px',
        lineHeight: '16.34px',
        marginBottom: '5px',
      },
    },
    MuiSwitch: {
      root: {
        width: 30,
        height: 15,
        padding: 0,
        margin: '8px',
        overflow: 'visible',
      },
      colorSecondary: {
        '&$thumb': { backgroundColor: $colors.mHubGray2 },
        '&$checked': {
          color: defaultTheme.palette.common.white,
          '& + $track': {
            backgroundColor: $colors.mHubMint,
          },
          '&$disabled': {
            color: defaultTheme.palette.common.white,
            '& + $track': {
              backgroundColor: $colors.mHubMint,
              opacity: '20%',
            },
          },
        },
        '&$disabled': {
          color: defaultTheme.palette.common.white,
          '& + $track': {
            backgroundColor: $colors.mHubGray3,
          },
        },
      },
      switchBase: {
        padding: 0,
        color: defaultTheme.palette.common.white,
        transform: 'translate(2px, 1px)',
        '&$checked': {
          border: `1px solid ${$colors.mHubMint}`,
          transform: 'translateX(15px)',
          '&$disabled': {
            border: `1px solid ${$colors.mHubGray3}`,
          },
          '& + $track': {
            opacity: 1,
            border: 'none',
          },
        },
        '&$disabled + $track': {
          opacity: 1,
          borderStyle: 'none',
        },
      },
      track: {
        borderRadius: 16 / 2,
        opacity: 1,
        transition: defaultTheme.transitions.create(['background-color', 'transform']),
        backgroundColor: $colors.mHubWhite,
        border: '1px solid #CACBCC',
      },
      thumb: {
        width: 13,
        height: 13,
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: '4px',
        backgroundColor: $colors.mHubSlate,
        fontSize: '10px',
        fontFamily: $fontFamily.openSans,
        fontWeight: $fontWeight.regular,
        lineHeight: '13.62px',
        padding: '3px 10px',
      },
    },
  },
  typography: {
    h1: {
      fontFamily: $fontFamily.montserrat,
      fontWeight: $fontWeight.semiBold,
      fontSize: $fontSize['24px'],
    },
    h2: {
      fontFamily: $fontFamily.montserrat,
      fontWeight: $fontWeight.semiBold,
      fontSize: $fontSize['22px'],
    },
    h3: {
      fontFamily: $fontFamily.montserrat,
      fontWeight: $fontWeight.semiBold,
      fontSize: $fontSize['21px'],
    },
    h4: {
      fontFamily: $fontFamily.montserrat,
      fontWeight: $fontWeight.semiBold,
      fontSize: $fontSize['19px'],
    },
    h5: {
      fontFamily: $fontFamily.montserrat,
      fontWeight: $fontWeight.semiBold,
      fontSize: $fontSize['17px'],
    },
    h6: {
      fontFamily: $fontFamily.montserrat,
      fontWeight: $fontWeight.semiBold,
      fontSize: $fontSize['15px'],
    },
    body1: {
      fontFamily: $fontFamily.openSans,
      fontWeight: $fontWeight.regular,
      fontSize: $fontSize['14px'],
    },
    body2: {
      fontFamily: $fontFamily.openSans,
      fontWeight: $fontWeight.regular,
      fontSize: $fontSize['12px'],
    },
    subtitle1: {
      fontFamily: $fontFamily.openSans,
      fontWeight: $fontWeight.semiBold,
      fontSize: $fontSize['14px'],
    },
    subtitle2: {
      fontFamily: $fontFamily.openSans,
      fontWeight: $fontWeight.semiBold,
      fontSize: $fontSize['12px'],
    },
    caption: {
      fontFamily: $fontFamily.openSans,
      fontWeight: $fontWeight.regular,
      fontSize: $fontSize['10px'],
    },
    overline: {
      fontFamily: $fontFamily.openSans,
      fontWeight: $fontWeight.bold,
      fontSize: $fontSize['10px'],
    },
  },
});

export default globalTheme;
