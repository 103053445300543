export default class CountryStateEntity {
  readonly label?: string;

  readonly value?: string;

  constructor(
    label?: string,
    value?: string,
  ) {
    this.label = label;
    this.value = value;
  }

  static fromData(data: {
    [name: string]: any,
  }): CountryStateEntity {
    return new CountryStateEntity(
      data.label,
      data.value,
    );
  }
}
