import axios from 'axios';

export interface AWSResponse {
  data: {
    error_message: string;
    error_type: string;
  };
  status: number;
  statusText: string;
  headers: { [key: string]: string };
}

export function isAWSResponse(obj: any): obj is AWSResponse {
  if (!obj
    || !obj.status
    || !obj.headers
    || !obj.data) {
    return false;
  }
  return typeof obj.status === 'number'
    && typeof obj.headers === 'object'
    && typeof obj.data === 'object';
}

export interface AWSError {
  code?: string;
  name?: string;
  response?: AWSResponse;
  message: string;
}

export function isAWSError(obj: any): obj is AWSError {
  if (obj.response) {
    return typeof obj.message === 'string' && isAWSResponse(obj.response);
  }
  return typeof obj.message === 'string'
    && typeof obj.code === 'string'
    && typeof obj.name === 'string';
}

export const UploadS3 = (
  presignedURL: string,
  fileType: string,
  fileContent: File,
  removeCacheControl?: boolean,
) => {
  const headers: any = {
    'Content-Type': fileType,
    'x-amz-acl': 'bucket-owner-full-control',
  };
  if (!removeCacheControl) {
    headers['cache-control'] = 'no-cache';
  }
  return axios({
    method: 'PUT',
    url: presignedURL,
    data: fileContent,
    headers,
  });
};
