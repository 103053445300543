import {
  Chip, Grid, IconButton, Typography,
} from '@material-ui/core';
import React from 'react';
import { infoChipTags, newInfo } from 'src/apps/reports/constants/newInfos';
import MHubIcon from '../../../../components/MHubIcon';
import { useStyles } from './NewsModal.style';

interface NewsModalProps {
  onClose: (event: React.MouseEvent<unknown>) => void;
}

export default function NewsModal({
  onClose,
}: NewsModalProps) {
  const classes = useStyles();

  const renderTag = (value: string) => {
    const chipTag = infoChipTags.find((e) => e.value === value);
    let style = '';
    switch (value) {
      case 'new': style = classes.new; break;
      case 'improvement': style = classes.improvement; break;
      case 'announcements': style = classes.announcements; break;
      default: break;
    }
    return (
      <Chip variant="outlined" label={chipTag?.label} className={style} />
    );
  };

  return (
    <div className={classes.container}>
      <Grid container className={classes.header}>
        <Grid container item justify="space-between">
          <Grid item>
            <Typography variant="h2">Analytics</Typography>
          </Grid>
          <Grid item>
            <IconButton size="small" onClick={onClose}>
              <MHubIcon name="cross" />
            </IconButton>
          </Grid>
        </Grid>
        <Grid>
          <Typography variant="body1">
            Discover new features with the brand-new Analytics
          </Typography>
        </Grid>
      </Grid>
      {
        newInfo.map((content) => (
          <Grid container direction="column" key={content.version} className={classes.section}>
            <Grid item container alignItems="baseline" className={classes.versionContainer}>
              <Typography variant="h5">
                {`Version ${content.version}`}
              </Typography>
              <Typography variant="body2">
                {`Released on ${content.release}`}
              </Typography>
            </Grid>
            {
              content.updates.map((update) => (
                <Grid item container key={`${update.category}_${content.version}`} direction="row">
                  <Grid item className={classes.chip}>
                    {renderTag(update.category)}
                  </Grid>
                  <Grid item className={classes.notes}>
                    <ul>
                      {
                        update.notes.map((note) => (
                          <li key={note}>
                            <Typography variant="body2">{note}</Typography>
                          </li>
                        ))
                      }
                    </ul>
                  </Grid>
                </Grid>
              ))
            }
          </Grid>
        ))
      }
    </div>
  );
}
