export const agingDateRange = [
  { text: 'Until today', value: 'until_today' },
  { text: 'More than 3 days', value: 'more_than_3_days' },
  { text: 'More than 7 days', value: 'more_than_7_days' },
  { text: 'More than 14 days', value: 'more_than_14_days' },
  { text: 'More than 30 days', value: 'more_than_30_days' },
];

export const lawyerCaseAgingDateRange = [
  { text: 'Until today', value: 'until_today' },
  { text: 'More than 3 days', value: '3' },
  { text: 'More than 7 days', value: '7' },
  { text: 'More than 14 days', value: '14' },
  { text: 'More than 30 days', value: '30' },
];
