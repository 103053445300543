export const $colors = {
  mHubWhite: '#FFFFFF',
  mHubTeal: '#49B3B1',
  mHubTealHover: '#32C8C5',
  mHubButter: '#F0CA28',
  mHubBlue: '#0094B3',
  mhubBgBlue: '#EDF7FD',
  mHubSky: '#32C8F5',
  mHubSpace: '#00233B',
  mHubSlate: '#596975',
  mHubGray1: '#969CA4',
  mHubGray2: '#DBDDDE',
  mHubGray2b: '#E7EAED',
  mHubGray3: '#F4F5F7',
  mHubGray4: '#F8F9F9',
  mHubGray5: '#BBBFC6',
  mHubGray6: '#CECAC1',
  mHubGray7: '#F2F4F7',
  mHubGray8: '#667085',
  mHubGray9: '#F8F9FC',
  mHubMint: '#50B793',
  mHubMint2: '#E8FAE5',
  mHubMintBg: '#E5FAF2',
  mHubJade: '#24A476',
  mHubRed: '#EE0000',
  mHubRedBg: '#FFEDED',
  mHubDarkRed: '#B42318',
  mHubMustard: '#F2B42A',
  mHubMustardBg: '#FFF6D1',
  mHubPeach: '#FF796E',
  mHubPeach2: '#F97066',
  mHubWarning: '#FFCB00',
  mHubOrange: '#F79A3E',
  mHubOrange2: '#F5BE3B',
  mHubOrange3: '#F79009',
  mHubOrangeBg: '#FFE5BF',
  mHubDarkOrange: '#B54708',
  mHubDarkOrange2: '#C4320A',
  mHubFuschia: '#EC4869',
  mHubOcean: '#3DA0E4',
  mHubLightBlue: '#3597EC',
  mHubPear: '#7FC556',
  mHubLightOrange: '#FFB000',
  mHubYellow: '#FBE30A',
  mHubYellowBg: '#FEFBED',
  mHubDarkGreen: '#027A48',
  mHubPinkBg: '#FEE4E2',
  mhubLightPurple: '#7282d729',
  mhubPurple: '#5068E4',
  mhubDarkPurple: '#7282D7',
  mhubBabyBlue: '#A1D3FF',
  mhubLilac: '#C8A2C8',
};

export const $fontWeight = {
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
};

export const $fontFamily = {
  openSans: '"Open Sans", serif',
  montserrat: '"Montserrat", serif',
};

export const $fontSize = {
  '10px': '0.625rem',
  '12px': '0.75rem',
  '14px': '0.875rem',
  '15px': '0.9375rem',
  '16px': '1rem',
  '17px': '1.0625rem',
  '18px': '1.125rem',
  '19px': '1.1875rem',
  '20px': '1.25rem',
  '21px': '1.3125rem',
  '22px': '1.375rem',
  '24px': '1.5rem',
  '32px': '25rem',
};

export const DESK_TOOLBAR_HEIGHT = '66px';
export const NAV_HEIGHT = 50;
export const DESK_DRAWER_WIDTH = 240;
export const DESK_DRAWER_ICON_WIDTH = 60;
export const DESK_DRAWER_SUBPANEL_WIDTH = 240;
export const DESK_CLOSE_WIDTH = 24;
export const DESK_DRAWER_ICON_AND_CLOSE_WIDTH = DESK_DRAWER_SUBPANEL_WIDTH - DESK_CLOSE_WIDTH;
