import {
  createContext, ReactNode, useContext, useState,
} from 'react';

interface IGenerateReportContext {
  newReportsList: any;
  isReportGenerated: boolean;
  isNewReportLoading: boolean;
  setNewReportsList: (val: any) => void;
  setIsReportGenerated: (val: boolean) => void;
  setIsNewReportLoading: (val: boolean) => void;
}

const ComponentContext = createContext<IGenerateReportContext>({
  newReportsList: [],
  isReportGenerated: false,
  isNewReportLoading: false,
  setNewReportsList: () => { },
  setIsReportGenerated: () => { },
  setIsNewReportLoading: () => { },
});

export function useProvideComponent(): IGenerateReportContext {
  const [newReportsList, setLatesListingData] = useState<any>([]);
  const [isReportGenerated, setIsNewReportGenerated] = useState<boolean>(false);
  const [isNewReportLoading, setIsNewReportListLoading] = useState<boolean>(false);

  const setNewReportsList = (state: any) => {
    setLatesListingData(state);
  };

  const setIsReportGenerated = (state: any) => {
    setIsNewReportGenerated(state);
  };

  const setIsNewReportLoading = (state: any) => {
    setIsNewReportListLoading(state);
  };

  return {
    newReportsList,
    isReportGenerated,
    isNewReportLoading,
    setNewReportsList,
    setIsReportGenerated,
    setIsNewReportLoading,
  };
}

export const useGenerateReportContext = () => useContext(ComponentContext);

export function GenerateAnalyticsReportProvider({
  children,
}: {
  children: ReactNode,
}) {
  const value = useProvideComponent();
  return (
    <ComponentContext.Provider value={value}>
      {children}
    </ComponentContext.Provider>
  );
}
