import { SvgIcon as Icon, Badge } from '@material-ui/core';
import clsx from 'clsx';

import { ReactComponent as ActivitySVG } from 'src/assets/icon/activity.svg';
import { ReactComponent as addCircleOutlinedSVG } from 'src/assets/icon/add_circle_outline.svg';
import { ReactComponent as AgencySVG } from 'src/assets/icon/agency.svg';
import { ReactComponent as AlertSVG } from 'src/assets/icon/alert.svg';
import { ReactComponent as AllocateSVG } from 'src/assets/icon/allocate.svg';
import { ReactComponent as ArrowDownSVG } from 'src/assets/icon/arrow_down.svg';
import { ReactComponent as ArrowLeftSVG } from 'src/assets/icon/arrow_left.svg';
import { ReactComponent as ArrowRightSVG } from 'src/assets/icon/arrow_right.svg';
import { ReactComponent as ArrowUpSVG } from 'src/assets/icon/arrow_up.svg';
import { ReactComponent as AvatarSVG } from 'src/assets/icon/avatar.svg';
import { ReactComponent as BankTransferActiveSVG } from 'src/assets/icon/bank_transfer_active.svg';
import { ReactComponent as BankTransferSVG } from 'src/assets/icon/bank_transfer.svg';
import { ReactComponent as BathSVG } from 'src/assets/icon/bath.svg';
import { ReactComponent as BedroomSVG } from 'src/assets/icon/bedroom.svg';
import { ReactComponent as BentoMenuSVG } from 'src/assets/icon/bento_menu.svg';
import { ReactComponent as BurgerMenuSVG } from 'src/assets/icon/burger_menu.svg';
import { ReactComponent as BuyerSVG } from 'src/assets/icon/buyer.svg';
import { ReactComponent as CalculatorSVG } from 'src/assets/icon/calculator.svg';
import { ReactComponent as CalendarSVG } from 'src/assets/icon/calendar.svg';
import { ReactComponent as CampaignSVG } from 'src/assets/icon/campaign.svg';
import { ReactComponent as CashActiveSVG } from 'src/assets/icon/cash_active.svg';
import { ReactComponent as CashSVG } from 'src/assets/icon/cash.svg';
import { ReactComponent as CheckmarkFilledSVG } from 'src/assets/icon/checkmark_filled.svg';
import { ReactComponent as CheckmarkOutlineSVG } from 'src/assets/icon/checkmark_outline.svg';
import { ReactComponent as ChequeActiveSVG } from 'src/assets/icon/cheque_active.svg';
import { ReactComponent as ChequeSVG } from 'src/assets/icon/cheque.svg';
import { ReactComponent as CircleFilledSVG } from 'src/assets/icon/circle_filled.svg';
import { ReactComponent as CloseFilledSVG } from 'src/assets/icon/close_filled.svg';
import { ReactComponent as CloseOutlinedSVG } from 'src/assets/icon/close_outline.svg';
import { ReactComponent as ColumnSVG } from 'src/assets/icon/columns.svg';
import { ReactComponent as CompareSVG } from 'src/assets/icon/compare.svg';
import { ReactComponent as CreditCardActiveSVG } from 'src/assets/icon/credit_card_active.svg';
import { ReactComponent as CreditCardSVG } from 'src/assets/icon/credit_card.svg';
import { ReactComponent as CreditCheckSVG } from 'src/assets/icon/credit_check.svg';
import { ReactComponent as CrossSVG } from 'src/assets/icon/cross.svg';
import { ReactComponent as DashboardSVG } from 'src/assets/icon/dashboard.svg';
import { ReactComponent as DashboardMenuSVG } from 'src/assets/icon/dashboardMenu.svg';
import { ReactComponent as DeleteSVG } from 'src/assets/icon/delete.svg';
import { ReactComponent as DocFailSVG } from 'src/assets/icon/doc_fail.svg';
import { ReactComponent as DocumentSVG } from 'src/assets/icon/document.svg';
import { ReactComponent as DollarSignSVG } from 'src/assets/icon/dollar_sign.svg';
import { ReactComponent as DownloadSVG } from 'src/assets/icon/download.svg';
import { ReactComponent as DragSVG } from 'src/assets/icon/drag.svg';
import { ReactComponent as DropdownArrowSVG } from 'src/assets/icon/dropdown_arrow.svg';
import { ReactComponent as DuplicateSVG } from 'src/assets/icon/duplicate.svg';
import { ReactComponent as EditSVG } from 'src/assets/icon/edit.svg';
import { ReactComponent as EyeOffSVG } from 'src/assets/icon/eye_off.svg';
import { ReactComponent as EyeOnSVG } from 'src/assets/icon/eye_on.svg';
import { ReactComponent as FilterSVG } from 'src/assets/icon/filter.svg';
import { ReactComponent as HelpSVG } from 'src/assets/icon/help.svg';
import { ReactComponent as IcBackSVG } from 'src/assets/icon/ic_back.svg';
import { ReactComponent as IcFrontSVG } from 'src/assets/icon/ic_front.svg';
import { ReactComponent as ImageSVG } from 'src/assets/icon/image.svg';
import { ReactComponent as InfoFilledSVG } from 'src/assets/icon/info_filled.svg';
import { ReactComponent as InfoSVG } from 'src/assets/icon/info.svg';
import { ReactComponent as LayoutSVG } from 'src/assets/icon/layout.svg';
import { ReactComponent as LocationSVG } from 'src/assets/icon/location.svg';
import { ReactComponent as LockSVG } from 'src/assets/icon/lock.svg';
import { ReactComponent as MailSVG } from 'src/assets/icon/mail.svg';
import { ReactComponent as MagnifyingGlassSVG } from 'src/assets/icon/magnifying_glass.svg';
import { ReactComponent as MarketplaceSVG } from 'src/assets/icon/marketplace.svg';
import { ReactComponent as MenuVerticalSVG } from 'src/assets/icon/menu_vertical.svg';
import { ReactComponent as MHubAccountSVG } from 'src/assets/icon/mhub_accounts.svg';
import { ReactComponent as MHubActiveSVG } from 'src/assets/icon/mhub_active.svg';
import { ReactComponent as MHubSVG } from 'src/assets/icon/mhub.svg';
import { ReactComponent as MobileSVG } from 'src/assets/icon/mobile.svg';
import { ReactComponent as NotesSVG } from 'src/assets/icon/notes.svg';
import { ReactComponent as OpenTabSVG } from 'src/assets/icon/open_tab.svg';
import { ReactComponent as OthersActiveSVG } from 'src/assets/icon/others_active.svg';
import { ReactComponent as OthersSVG } from 'src/assets/icon/others.svg';
import { ReactComponent as PointerSVG } from 'src/assets/icon/pointer.svg';
import { ReactComponent as PrintSVG } from 'src/assets/icon/print.svg';
import { ReactComponent as ProfileSVG } from 'src/assets/icon/profile.svg';
import { ReactComponent as PropertySVG } from 'src/assets/icon/property.svg';
import { ReactComponent as RefreshSVG } from 'src/assets/icon/refresh.svg';
import { ReactComponent as ReportsDownloadSVG } from 'src/assets/icon/reports_download.svg';
import { ReactComponent as ReserveSVG } from 'src/assets/icon/reserve.svg';
import { ReactComponent as SearchSVG } from 'src/assets/icon/search.svg';
import { ReactComponent as SignOutSVG } from 'src/assets/icon/log_out.svg';
import { ReactComponent as SizeSVG } from 'src/assets/icon/size.svg';
import { ReactComponent as SolidArrowDownSVG } from 'src/assets/icon/solid_arrow_down.svg';
import { ReactComponent as SortSVG } from 'src/assets/icon/sort.svg';
import { ReactComponent as SrbSVG } from 'src/assets/icon/srb.svg';
import { ReactComponent as StarSVG } from 'src/assets/icon/star.svg';
import { ReactComponent as TimerSVG } from 'src/assets/icon/timer.svg';
import { ReactComponent as TimeSVG } from 'src/assets/icon/time.svg';
import { ReactComponent as UnitsSVG } from 'src/assets/icon/units.svg';
import { ReactComponent as UploadPdfSVG } from 'src/assets/icon/upload_pdf.svg';
import { ReactComponent as UploadSVG } from 'src/assets/icon/upload.svg';
import { ReactComponent as ViewSVG } from 'src/assets/icon/view.svg';
import { ReactComponent as VirtualTourSVG } from 'src/assets/icon/virtual_tour.svg';
import { ReactComponent as WarningSVG } from 'src/assets/icon/warning.svg';

import { MHubIconMap, MHubIconProps } from './MHubIcon.types';
import useStyles from './MHubIcon.styles';

const iconMap: MHubIconMap = {
  activity: ActivitySVG,
  addCircleOutlined: addCircleOutlinedSVG,
  agency: AgencySVG,
  alert: AlertSVG,
  allocate: AllocateSVG,
  arrowDown: ArrowDownSVG,
  arrowLeft: ArrowLeftSVG,
  arrowRight: ArrowRightSVG,
  arrowUp: ArrowUpSVG,
  avatar: AvatarSVG,
  bankTransfer: BankTransferSVG,
  bankTransferActive: BankTransferActiveSVG,
  bath: BathSVG,
  bedroom: BedroomSVG,
  bentoMenu: BentoMenuSVG,
  burgerMenu: BurgerMenuSVG,
  buyer: BuyerSVG,
  calculator: CalculatorSVG,
  calendar: CalendarSVG,
  campaign: CampaignSVG,
  cash: CashSVG,
  cashActive: CashActiveSVG,
  checkmarkFilled: CheckmarkFilledSVG,
  checkmarkOutlined: CheckmarkOutlineSVG,
  cheque: ChequeSVG,
  chequeActive: ChequeActiveSVG,
  circleFilled: CircleFilledSVG,
  closeFilled: CloseFilledSVG,
  closeOutlined: CloseOutlinedSVG,
  column: ColumnSVG,
  compare: CompareSVG,
  creditCard: CreditCardSVG,
  creditCardActive: CreditCardActiveSVG,
  creditCheck: CreditCheckSVG,
  cross: CrossSVG,
  dashboard: DashboardSVG,
  dashboardMenu: DashboardMenuSVG,
  delete: DeleteSVG,
  docFail: DocFailSVG,
  document: DocumentSVG,
  dollarSign: DollarSignSVG,
  download: DownloadSVG,
  drag: DragSVG,
  dropdownArrow: DropdownArrowSVG,
  duplicate: DuplicateSVG,
  edit: EditSVG,
  eyeOff: EyeOffSVG,
  eyeOn: EyeOnSVG,
  filter: FilterSVG,
  help: HelpSVG,
  icBack: IcBackSVG,
  icFront: IcFrontSVG,
  image: ImageSVG,
  info: InfoSVG,
  infoFilled: InfoFilledSVG,
  layout: LayoutSVG,
  location: LocationSVG,
  lock: LockSVG,
  mail: MailSVG,
  magnifyingGlass: MagnifyingGlassSVG,
  marketplace: MarketplaceSVG,
  menuVertical: MenuVerticalSVG,
  mhub: MHubSVG,
  mhubAccount: MHubAccountSVG,
  mhubActive: MHubActiveSVG,
  mobile: MobileSVG,
  notes: NotesSVG,
  openTab: OpenTabSVG,
  others: OthersSVG,
  othersActive: OthersActiveSVG,
  pointer: PointerSVG,
  print: PrintSVG,
  profile: ProfileSVG,
  property: PropertySVG,
  refresh: RefreshSVG,
  reportsDownload: ReportsDownloadSVG,
  reserve: ReserveSVG,
  search: SearchSVG,
  signOut: SignOutSVG,
  size: SizeSVG,
  solidArrowDown: SolidArrowDownSVG,
  sort: SortSVG,
  srb: SrbSVG,
  star: StarSVG,
  time: TimeSVG,
  timer: TimerSVG,
  units: UnitsSVG,
  upload: UploadSVG,
  uploadPdf: UploadPdfSVG,
  view: ViewSVG,
  virtualTour: VirtualTourSVG,
  warning: WarningSVG,
};

const MHubIcon: React.FC<MHubIconProps> = ({
  withBadge = false,
  className,
  name,
  BadgeProps,
  size,
  ...rest
}: MHubIconProps): React.ReactElement => {
  const classes = useStyles({ size });
  const icon = (
    <Icon
      viewBox={rest.viewBox || '0 0 24 24'}
      className={clsx(classes.iconBaseStyle, className)}
      component={iconMap[name]}
      {...rest}
    />
  );
  if (withBadge) {
    return (
      <Badge
        variant="dot"
        classes={{ badge: classes.badge__badge }}
        {...BadgeProps}
      >
        {icon}
      </Badge>
    );
  }
  return icon;
};

export default MHubIcon;
