import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  AppBar, Badge, Chip, Dialog, Grid, IconButton, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableRow, Toolbar, Typography,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import logo from 'src/assets/MHub_logo_desktop.svg';
import MHubIcon from 'src/components/MHubIcon';
import { CompanyApp } from 'src/models/companyApp';
import { useAuth } from 'src/hooks/useAuth';
import { useCompanyContext } from 'src/hooks/useCompany';
import { getAppUrl } from 'src/utils/url';
import { handleCheckAppAccess } from 'src/utils/app';
import { hasAccess } from 'src/utils/role';
import { useProvidePage } from 'src/apps/reports/hooks/usePage';
import { useStyles } from './DesktopNav.style';
import NewsModal from '../../apps/reports/components/NewsModal/NewsModal';

const DesktopNav: React.FC = () => {
  const { pathname } = window.location;
  const classes = useStyles();
  const [helpAnchorEl, setHelpAnchorEl] = useState<Element | null>(null);
  const openHelpAnchor = Boolean(helpAnchorEl);
  const [openNewInfoModal, setOpenNewInfoModal] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const openAnchor = Boolean(anchorEl);
  const [showroomURL, setShowroomURL] = useState('');
  const {
    name,
    userCompanyId,
    userGroup,
    signOut,
  } = useAuth();
  const { findCompany } = useCompanyContext();
  const { company } = useCompanyContext();
  const {
    showWhatsNewModal,
    clickQuestionIcon,
    clickStarIcon,
  } = useProvidePage();

  useEffect(() => {
    const currentAppVersion = `${process.env.REACT_APP_VERSION}`;
    if (localStorage.getItem('lastReleaseNoteVersionSeen') && localStorage.getItem('lastReleaseNoteVersionSeen') !== currentAppVersion) {
      localStorage.setItem('isQuestionIconClicked', 'false');
      localStorage.setItem('isStarIconClicked', 'false');
    }
  }, []);

  useEffect(() => {
    if (userCompanyId) {
      findCompany(userCompanyId);
    }
  }, [userCompanyId]);

  useEffect(() => {
    const url = getAppUrl(company, 'showroom');
    setShowroomURL(url ?? '');
  }, [company]);

  const {
    REACT_APP_MHUB_ACCOUNT_URL,
    REACT_APP_MHUB_LOAN_TRACKER_URL,
    REACT_APP_MHUB_LEAD_URL,
    REACT_APP_MHUB_CREDIT_CONTROL_URL,
    REACT_APP_MHUB_ANALYTICS_URL,
    REACT_APP_MHUB_COMMISSION_TRACKER_URL,
  } = process.env;

  const handleOpenLink = (url: any) => {
    if (url) {
      window.open(url, '_blank');
    }
  };

  const handleAnchorElClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAnchorElClose = () => {
    setAnchorEl(null);
  };

  const handleHelpAnchorElClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setHelpAnchorEl(event.currentTarget);
    clickQuestionIcon(true);
  };

  const handleHelpAnchorElClose = () => {
    setHelpAnchorEl(null);
  };

  const handleWhatsNewOpen = () => {
    setHelpAnchorEl(null);
    setOpenNewInfoModal(true);
    clickStarIcon(true);
  };

  const handleWhatsNewClose = () => {
    showWhatsNewModal(true);
    setOpenNewInfoModal(false);
  };

  const generateAppLink = (selectedApp: string, apps: CompanyApp[]) => {
    const selectedAppData = apps?.find((app) => app.appID === selectedApp);

    if (selectedAppData?.webURL) {
      return selectedAppData.webURL;
    }

    switch (selectedApp) {
      case 'loan_tracker':
        return REACT_APP_MHUB_LOAN_TRACKER_URL;
      case 'lead_management_v2':
        return REACT_APP_MHUB_LEAD_URL;
      case 'credit_control':
        return REACT_APP_MHUB_CREDIT_CONTROL_URL;
      case 'analytics':
        return REACT_APP_MHUB_ANALYTICS_URL;
      case 'commission_tracker':
        return REACT_APP_MHUB_COMMISSION_TRACKER_URL;
      default:
        break;
    }

    return '';
  };

  const renderMHubProductNavigations = () => (
    <ul className={classes.ul}>
      <li>
        <a href={pathname.startsWith('/analytics') ? '/' : showroomURL} className={classes.logo}>
          <img alt="MHub Developer" src={logo} />
        </a>
      </li>
      <li>
        <a
          className={clsx(classes.generalLink, !pathname.startsWith('/analytics') ? classes.activeLink : '')}
          href={showroomURL}
        >
          Showroom
        </a>
      </li>
      {
        handleCheckAppAccess('loan_tracker', company.apps) && (
          <li>
            <a
              className={classes.generalLink}
              href={generateAppLink('loan_tracker', company.apps)}
            >
              Loan Tracker
            </a>
          </li>
        )
      }

      {
        handleCheckAppAccess('lead_management_v2', company.apps) && (
          <li>
            <a
              className={classes.generalLink}
              href={generateAppLink('lead_management_v2', company.apps)}
            >
              CRM
            </a>
          </li>
        )
      }

      {
        handleCheckAppAccess('credit_control', company.apps) && (
          <li>
            <a
              className={classes.generalLink}
              href={generateAppLink('credit_control', company.apps)}
            >
              Credit Control
            </a>
          </li>
        )
      }

      {
        handleCheckAppAccess('analytics', company.apps) && hasAccess(userGroup) && (
          <li>
            <a
              className={clsx(classes.generalLink, pathname.startsWith('/analytics') ? classes.activeLink : '')}
              href={generateAppLink('analytics', company.apps)}
            >
              Analytics
            </a>
          </li>
        )
      }

      {
        handleCheckAppAccess('commission_tracker', company.apps) && (
          <li>
            <a
              className={classes.generalLink}
              href={generateAppLink('commission_tracker', company.apps)}
            >
              Commission
            </a>
            <Chip className={classes.betaChip} label="BETA" />
          </li>
        )
      }
      {/* To do: To temporarily hide prior to analytics release */}
      {/* <li>
        <a
          href="/analytics/sales/overview"
          className={clsx(classes.generalLink,
            pathname.startsWith('/analytics') ? classes.activeLink : '')}
        >
          Analytics
        </a>
      </li> */}
    </ul>
  );

  const mobileScreen = useMediaQuery(useTheme().breakpoints.down('sm'));

  const renderDesktopView = () => (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          className={classes.toolbarHeight}
        >
          <Grid item xs={10}>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              className={classes.headerStyle}
            >
              {renderMHubProductNavigations()}
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow className={classes.buttonContainer}>
                    <TableCell colSpan={1}>
                      <IconButton
                        id="help"
                        color="inherit"
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={(event) => handleHelpAnchorElClick(event)}
                        className={classes.helpButton}
                      >
                        <Badge color="error" variant="dot" invisible={localStorage.getItem('isQuestionIconClicked') === 'true'}>
                          <MHubIcon name="help" />
                        </Badge>
                      </IconButton>
                      <Menu
                        className={classes.anchorBadgeMenu}
                        elevation={0}
                        id="long-menu"
                        anchorEl={helpAnchorEl}
                        keepMounted
                        open={openHelpAnchor}
                        onClose={handleHelpAnchorElClose}
                        transformOrigin={{
                          vertical: -10,
                          horizontal: 160,
                        }}
                      >
                        <MenuItem
                          disableRipple
                          className={classes.nameText}
                        >
                          <Typography noWrap>Help</Typography>
                        </MenuItem>
                        <MenuItem
                          onClick={() => handleWhatsNewOpen()}
                        >
                          <Badge color="error" variant="dot" invisible={localStorage.getItem('isStarIconClicked') === 'true'}>
                            <MHubIcon name="star" />
                          </Badge>
                          What&apos;s new
                        </MenuItem>
                      </Menu>
                      <Dialog
                        open={openNewInfoModal}
                        maxWidth="sm"
                        fullWidth
                        onClose={handleWhatsNewClose}
                      >
                        <NewsModal onClose={handleWhatsNewClose} />
                      </Dialog>
                    </TableCell>
                    <TableCell colSpan={1}>
                      <IconButton
                        id="menu"
                        color="inherit"
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={handleAnchorElClick}
                        className={classes.menuButton}
                      >
                        <MHubIcon name="profile" />
                      </IconButton>
                      <Menu
                        className={classes.anchorMenu}
                        elevation={0}
                        id="long-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={openAnchor}
                        onClose={handleAnchorElClose}
                      >
                        <MenuItem
                          disableRipple
                          className={classes.nameText}
                        >
                          <Typography noWrap>{name}</Typography>
                        </MenuItem>
                        <MenuItem
                          onClick={() => handleOpenLink(`${showroomURL}/profile/`)}
                        >
                          <MHubIcon name="profile" />
                          Profile
                        </MenuItem>
                        {
                          hasAccess(userGroup) ? (
                            <MenuItem
                              onClick={() => handleOpenLink(REACT_APP_MHUB_ACCOUNT_URL)}
                            >
                              <MHubIcon name="mhubAccount" />
                              MHub account
                            </MenuItem>
                          ) : null
                        }
                        <MenuItem
                          onClick={() => {
                            signOut();
                          }}
                        >
                          <MHubIcon name="signOut" />
                          Sign Out
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );

  return (
    <>
      { !mobileScreen ? renderDesktopView() : null }
    </>
  );
};

export default DesktopNav;
