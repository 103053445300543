import {
  format,
  startOfWeek,
  startOfYear,
  endOfWeek,
  endOfYear,
  sub,
  differenceInCalendarMonths,
  differenceInCalendarQuarters,
  differenceInCalendarYears,
} from 'date-fns';

const formatMMMYYYY = (dt: Date) => format(dt, 'LLL yyyy');
export const formatMMMDYYYY = (dt: Date) => format(dt, 'LLL d, yyyy');
export const formatYYYYMMDD = (dt: Date) => format(dt, 'yyyy-MM-dd');

export const today = new Date();
export const yesterday = sub(today, {
  days: 1,
});
const allTimeStart = new Date(2019, 0, 0);
const yearStartDt = startOfYear(today);

export const dateRange = {
  thisWeek: {
    start: startOfWeek(today),
    end: endOfWeek(today),
  },
  lastWeek: {
    start: sub(startOfWeek(today), { days: 7 }),
    end: sub(endOfWeek(today), { days: 7 }),
  },
  lastSevenDays: {
    start: sub(today, { days: 7 }),
    end: yesterday,
  },
  lastThirtyDays: {
    start: sub(today, { days: 30 }),
    end: yesterday,
  },
  lastNinetyDays: {
    start: sub(today, { days: 90 }),
    end: yesterday,
  },
  lastTwelveMonths: {
    start: sub(today, { days: 1, months: 12 }),
    end: yesterday,
  },
  lastCalendarYear: {
    start: sub(yearStartDt, { years: 1 }),
    end: sub(endOfYear(today), { years: 1 }),
  },
  thisYear: {
    start: yearStartDt,
    end: endOfYear(today),
  },
  allTime: {
    start: allTimeStart,
    end: today,
  },
};

export interface IDateRange {
  fromDatetime: string;
  toDatetime: string;
}

export interface IDtRangeChoices {
  id: string;
  name: string;
  range: string;
  startDt: Date | null;
  endDt: Date | null;
  filter: IDateRange;
}

export const dtRangeChoices: IDtRangeChoices[] = [
  {
    id: 'today',
    name: 'Today',
    startDt: today,
    endDt: today,
    range: `${formatMMMDYYYY(today)}`,
    filter: {
      fromDatetime: formatYYYYMMDD(today),
      toDatetime: formatYYYYMMDD(today),
    },
  },
  {
    id: 'yesterday',
    name: 'Yesterday',
    startDt: yesterday,
    endDt: yesterday,
    range: `${formatMMMDYYYY(yesterday)}`,
    filter: {
      fromDatetime: formatYYYYMMDD(yesterday),
      toDatetime: formatYYYYMMDD(yesterday),
    },
  },
  {
    id: 'thisWeek',
    name: 'This week',
    startDt: dateRange.thisWeek.start,
    endDt: dateRange.thisWeek.end,
    range: `${formatMMMDYYYY(dateRange.thisWeek.start)} - ${formatMMMDYYYY(dateRange.thisWeek.end)}`,
    filter: {
      fromDatetime: formatYYYYMMDD(dateRange.thisWeek.start),
      toDatetime: formatYYYYMMDD(dateRange.thisWeek.end),
    },
  },
  {
    id: 'lastWeek',
    name: 'Last week',
    startDt: dateRange.lastWeek.start,
    endDt: dateRange.lastWeek.end,
    range: `${formatMMMDYYYY(
      dateRange.lastWeek.start,
    )} - ${formatMMMDYYYY(dateRange.lastWeek.end)}`,
    filter: {
      fromDatetime: formatYYYYMMDD(dateRange.lastWeek.start),
      toDatetime: formatYYYYMMDD(dateRange.lastWeek.end),
    },
  },
  {
    id: 'lastSevenDays',
    name: 'Last 7 days',
    startDt: dateRange.lastSevenDays.start,
    endDt: dateRange.lastSevenDays.end,
    range: `${formatMMMDYYYY(dateRange.lastSevenDays.start)} - ${formatMMMDYYYY(
      dateRange.lastSevenDays.end,
    )}`,
    filter: {
      fromDatetime: formatYYYYMMDD(dateRange.lastSevenDays.start),
      toDatetime: formatYYYYMMDD(dateRange.lastSevenDays.end),
    },
  },
  {
    id: 'lastThirtyDays',
    name: 'Last 30 days',
    startDt: dateRange.lastThirtyDays.start,
    endDt: dateRange.lastThirtyDays.end,
    range: `${formatMMMDYYYY(dateRange.lastThirtyDays.start)} - ${formatMMMDYYYY(
      dateRange.lastThirtyDays.end,
    )}`,
    filter: {
      fromDatetime: formatYYYYMMDD(dateRange.lastThirtyDays.start),
      toDatetime: formatYYYYMMDD(dateRange.lastThirtyDays.end),
    },
  },
  {
    id: 'lastNinetyDays',
    name: 'Last 90 days',
    startDt: dateRange.lastNinetyDays.start,
    endDt: dateRange.lastNinetyDays.end,
    range: `${formatMMMDYYYY(dateRange.lastNinetyDays.start)} - ${formatMMMDYYYY(
      dateRange.lastNinetyDays.end,
    )}`,
    filter: {
      fromDatetime: formatYYYYMMDD(dateRange.lastNinetyDays.start),
      toDatetime: formatYYYYMMDD(dateRange.lastNinetyDays.end),
    },
  },
  {
    id: 'lastTwelveMonths',
    name: 'Last 12 months',
    startDt: dateRange.lastTwelveMonths.start,
    endDt: dateRange.lastTwelveMonths.end,
    range: `${formatMMMDYYYY(
      dateRange.lastTwelveMonths.start,
    )} - ${formatMMMDYYYY(dateRange.lastTwelveMonths.end)}`,
    filter: {
      fromDatetime: formatYYYYMMDD(dateRange.lastTwelveMonths.start),
      toDatetime: formatYYYYMMDD(dateRange.lastTwelveMonths.end),
    },
  },
  {
    id: 'lastCalendarYear',
    name: 'Last calendar year',
    startDt: dateRange.lastCalendarYear.start,
    endDt: dateRange.lastCalendarYear.end,
    range: `${formatMMMYYYY(dateRange.lastCalendarYear.start)} - ${formatMMMYYYY(dateRange.lastCalendarYear.end)}`,
    filter: {
      fromDatetime: formatYYYYMMDD(dateRange.lastCalendarYear.start),
      toDatetime: formatYYYYMMDD(dateRange.lastCalendarYear.end),
    },
  },
  {
    id: 'thisYear',
    name: 'This year (Jan-today)',
    startDt: dateRange.thisYear.start,
    endDt: dateRange.thisYear.end,
    range: `${formatMMMYYYY(dateRange.thisYear.start)} - Present`,
    filter: {
      fromDatetime: formatYYYYMMDD(dateRange.thisYear.start),
      toDatetime: formatYYYYMMDD(dateRange.thisYear.end),
    },
  },
  {
    id: 'custom',
    name: 'Custom',
    startDt: null,
    endDt: null,
    range: '',
    filter: {
      fromDatetime: '',
      toDatetime: '',
    },
  },
];

export const calcMonthDifference = (toDatetime: string, fromDatetime: string) => {
  const months = differenceInCalendarMonths(new Date(toDatetime), new Date(fromDatetime));
  return months;
};

export const calcQuarterDifference = (toDatetime: string, fromDatetime: string) => {
  const quarter = differenceInCalendarQuarters(new Date(toDatetime), new Date(fromDatetime));
  return quarter;
};

export const calcYearDifference = (toDatetime: string, fromDatetime: string) => {
  const year = differenceInCalendarYears(new Date(toDatetime), new Date(fromDatetime));
  return year;
};

export const dateFormatter = (d: Date, type: string) => {
  const date = format(d, type);
  return date;
};
