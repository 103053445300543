export enum BookingStatusID {
  NEW = 'reserved',
  BOOKED = 'booked',
  CANCELLED_DEVELOPER = 'cancelled_developer',
  CANCELLED_AVAILABILITY = 'cancelled_availability',
  SOLD = 'sold_spa_signed',
  EXPIRED = 'expired',
}

export class BookingStatus {
  readonly id?: BookingStatusID = undefined;

  readonly name?: string = '';

  readonly description?: string = '';

  readonly unitValue?: number = 0;

  readonly createdAt?: Date = undefined;

  readonly updatedAt?: Date = undefined;

  constructor(bookingStatus?: BookingStatus) {
    Object.assign(this, bookingStatus);
  }
}
