import {
  createContext, ReactNode, useContext, useState,
} from 'react';

interface IPageContext {
  isShowWhatsNewModal: any;
  isQuestionIconClicked: any;
  isStarIconClicked: any;
  lastReleaseNoteVersionSeen: string;
  clickQuestionIcon: (val: any) => void;
  clickStarIcon: (val: any) => void;
  showWhatsNewModal: (val: any) => void;
}

const PageContext = createContext<IPageContext>({
  isShowWhatsNewModal: false,
  isQuestionIconClicked: false,
  isStarIconClicked: false,
  lastReleaseNoteVersionSeen: '',
  clickQuestionIcon: () => { },
  clickStarIcon: () => { },
  showWhatsNewModal: () => { },
});

export function useProvidePage(): IPageContext {
  const [isShowWhatsNewModal, setIsShowWhatsNewModal] = useState(null);
  const [isQuestionIconClicked, setIsQuestionIconClicked] = useState(null);
  const [isStarIconClicked, setIsStarIconClicked] = useState(null);
  const [lastReleaseNoteVersionSeen, setLastReleaseNoteVersionSeen] = useState<string>('');

  const storageAvailable = (type: string) => {
    const test = type;
    try {
      localStorage.setItem(test, test);
      localStorage.removeItem(test);
      return true;
    } catch (err) {
      return false;
    }
  };

  const showWhatsNewModal = (state: any) => {
    const currentAppVersion = `${process.env.REACT_APP_VERSION}`;
    setIsShowWhatsNewModal(state);
    setLastReleaseNoteVersionSeen(currentAppVersion);

    if (storageAvailable('localStorage')) {
      localStorage.setItem('lastReleaseNoteVersionSeen', currentAppVersion);
      localStorage.setItem('isQuestionIconClicked', 'true');
      localStorage.setItem('isShowWhatsNewModal', 'true');
      localStorage.setItem('isStarIconClicked', 'true');
    }
  };

  const clickQuestionIcon = (state: any) => {
    setIsQuestionIconClicked(state);

    if (storageAvailable('localStorage')) {
      if (!(localStorage.getItem('isQuestionIconClicked') && localStorage.getItem('isQuestionIconClicked') !== 'true')) {
        localStorage.setItem('isQuestionIconClicked', 'true');
      }
    }
  };

  const clickStarIcon = (state: any) => {
    setIsStarIconClicked(state);

    if (storageAvailable('localStorage')) {
      if (!(localStorage.getItem('isStarIconClicked') && localStorage.getItem('isStarIconClicked') !== 'true')) {
        localStorage.setItem('isStarIconClicked', 'true');
      }
    }
  };

  return {
    isShowWhatsNewModal,
    isQuestionIconClicked,
    isStarIconClicked,
    lastReleaseNoteVersionSeen,
    showWhatsNewModal,
    clickQuestionIcon,
    clickStarIcon,
  };
}

export const useFilter = () => useContext(PageContext);

export function PageProvider({
  children,
}: {
  children: ReactNode,
}) {
  const value = useProvidePage();
  return (
    <PageContext.Provider value={value}>
      {children}
    </PageContext.Provider>
  );
}
