import { isArrayData } from '../utils';
import CountryStateEntity from './countryStateEntity';

export default class CountryEntity {
  readonly label?: string;

  readonly value?: string;

  readonly callingCode?: string;

  readonly states?: CountryStateEntity[];

  constructor(
    label?: string,
    value?: string,
    callingCode?: string,
    states?: CountryStateEntity[],
  ) {
    this.label = label;
    this.value = value;
    this.callingCode = callingCode;
    this.states = states;
  }

  static fromData(data: {
    [name: string]: any,
  }): CountryEntity {
    let states: CountryStateEntity[] | undefined;
    if (data.states && isArrayData(data.states)) {
      states = data.states.map((d) => CountryStateEntity.fromData(d));
    }
    return new CountryEntity(
      data.label,
      data.value,
      data.calling_code,
      states,
    );
  }
}
