import { MenuItemProps } from 'src/components/DropdownMenu/DropdownMenu.types';
import { BankCaseStatusType as bcst } from 'src/models/bankCase';

export const bankCaseStatuses: MenuItemProps[] = [
  { text: 'New', value: bcst.NEW },
  { text: 'Wrong info', value: bcst.REJECT_WRONG_INFO },
  { text: 'Unreachable', value: bcst.PENDING_UNREACHABLE },
  { text: 'Pending docs', value: bcst.PENDING_DOCS },
  { text: 'Docs submitted', value: bcst.PENDING_DOCS_SUBMITTED },
  { text: 'LO issued', value: bcst.APPROVED_LO_ISSUED },
  { text: 'Loan re-appealed', value: bcst.PENDING_REAPPEALED },
  { text: 'LO signed', value: bcst.SUCCESS_LI_ISSUED },
  { text: 'Loan rejected', value: bcst.REJECT_BANK },
  { text: 'Customer rejected LO', value: bcst.REJECT_CUSTOMER },
  { text: 'Cancelled by customer', value: bcst.REJECT_CANCELLED },
  { text: 'Cancelled by developer', value: bcst.REJECT_DEVELOPER },
  { text: 'Unqualified', value: bcst.REJECT_UNQUALIFIED },
  { text: 'LO signed with another bank', value: bcst.REJECT_LO_SIGNED_OTHER_BANK },
];

export const successBankStatusIDs = [bcst.SUCCESS_LI_ISSUED];
export const rejectBankStatusIDs = [
  bcst.REJECT_WRONG_INFO,
  bcst.REJECT_CANCELLED,
  bcst.REJECT_UNQUALIFIED,
  bcst.REJECT_BANK,
  bcst.REJECT_CUSTOMER,
  bcst.REJECT_LO_SIGNED_OTHER_BANK,
  bcst.REJECT_DEVELOPER,
];

export const UNAVAILABLE_BANKCASE_STATUS = 'N/a';
