export const banks = [
  { value: 'Commercial Local Banks', text: 'Commercial Local Banks', disabled: true },
  { value: 'Affin Bank Berhad', text: 'Affin Bank Berhad', disabled: false },
  { value: 'Alliance Bank Malaysia Berhad', text: 'Alliance Bank Malaysia Berhad', disabled: false },
  { value: 'AmBank (M) Berhad', text: 'AmBank (M) Berhad', disabled: false },
  { value: 'CIMB Bank Berhad', text: 'CIMB Bank Berhad', disabled: false },
  { value: 'Hong Leong Bank Berhad', text: 'Hong Leong Bank Berhad', disabled: false },
  { value: 'Malayan Banking Berhad', text: 'Malayan Banking Berhad', disabled: false },
  { value: 'Public Bank Berhad', text: 'Public Bank Berhad', disabled: false },
  { value: 'RHB Bank Berhad', text: 'RHB Bank Berhad', disabled: false },
  { value: 'Commercial Foreign Banks', text: 'Commercial Foreign Banks', disabled: true },
  { value: 'BNP Paribas Malaysia Berhad', text: 'BNP Paribas Malaysia Berhad', disabled: false },
  { value: 'Bangkok Bank Berhad', text: 'Bangkok Bank Berhad', disabled: false },
  { value: 'Bank of America Malaysia Berhad', text: 'Bank of America Malaysia Berhad', disabled: false },
  { value: 'Bank of China (Malaysia) Berhad', text: 'Bank of China (Malaysia) Berhad', disabled: false },
  { value: 'Bank of Tokyo-Mitsubishi UFJ (Malaysia) Berhad', text: 'Bank of Tokyo-Mitsubishi UFJ (Malaysia) Berhad', disabled: false },
  { value: 'China Construction Bank (Malaysia) Berhad[2]', text: 'China Construction Bank (Malaysia) Berhad[2]', disabled: false },
  { value: 'Citibank Berhad', text: 'Citibank Berhad', disabled: false },
  { value: 'Deutsche Bank (Malaysia) Berhad', text: 'Deutsche Bank (Malaysia) Berhad', disabled: false },
  { value: 'HSBC Bank Malaysia Berhad', text: 'HSBC Bank Malaysia Berhad', disabled: false },
  { value: 'India International Bank (Malaysia) Berhad', text: 'India International Bank (Malaysia) Berhad', disabled: false },
  { value: 'Industrial and Commercial Bank of China (Malaysia) Berhad', text: 'Industrial and Commercial Bank of China (Malaysia) Berhad', disabled: false },
  { value: 'J.P. Morgan Chase Bank Berhad', text: 'J.P. Morgan Chase Bank Berhad', disabled: false },
  { value: 'Mizuho Bank (Malaysia) Berhad', text: 'Mizuho Bank (Malaysia) Berhad', disabled: false },
  { value: 'OCBC Bank (Malaysia) Berhad', text: 'OCBC Bank (Malaysia) Berhad', disabled: false },
  { value: 'Standard Chartered Bank Malaysia Berhad', text: 'Standard Chartered Bank Malaysia Berhad', disabled: false },
  { value: 'Sumitomo Mitsui Banking Corporation Malaysia Berhad', text: 'Sumitomo Mitsui Banking Corporation Malaysia Berhad', disabled: false },
  { value: 'The Bank of Nova Scotia Berhad', text: 'The Bank of Nova Scotia Berhad', disabled: false },
  { value: 'United Overseas Bank (Malaysia) Bhd.', text: 'United Overseas Bank (Malaysia) Bhd.', disabled: false },
  { value: 'Islamic banks', text: 'Islamic banks', disabled: true },
  { value: 'Affin Islamic Bank Berhad', text: 'Affin Islamic Bank Berhad', disabled: false },
  { value: 'Al Rajhi Bank', text: 'Al Rajhi Bank', disabled: false },
  { value: 'Alkhair International Islamic Bank Bhd', text: 'Alkhair International Islamic Bank Bhd', disabled: false },
  { value: 'Alliance Islamic Bank Berhad', text: 'Alliance Islamic Bank Berhad', disabled: false },
  { value: 'AmBank Islamic Berhad', text: 'AmBank Islamic Berhad', disabled: false },
  { value: 'MBSB Bank Berhad', text: 'MBSB Bank Berhad', disabled: false },
  { value: 'Asia Offshore Finance Agency', text: 'Asia Offshore Finance Agency', disabled: false },
  { value: 'Bank Islam Malaysia', text: 'Bank Islam Malaysia', disabled: false },
  { value: 'Bank Muamalat Malaysia', text: 'Bank Muamalat Malaysia', disabled: false },
  { value: 'CIMB Islamic Bank Berhad', text: 'CIMB Islamic Bank Berhad', disabled: false },
  { value: 'Hong Leong Islamic Banking Berhad', text: 'Hong Leong Islamic Banking Berhad', disabled: false },
  { value: 'HSBC Amanah Malaysia Berhad', text: 'HSBC Amanah Malaysia Berhad', disabled: false },
  { value: 'Kuwait Finance House (Malaysia) Berhad', text: 'Kuwait Finance House (Malaysia) Berhad', disabled: false },
  { value: 'Maybank Islamic Berhad', text: 'Maybank Islamic Berhad', disabled: false },
  { value: 'OCBC Al-Amin Bank Berhad', text: 'OCBC Al-Amin Bank Berhad', disabled: false },
  { value: 'Public Islamic Bank Berhad', text: 'Public Islamic Bank Berhad', disabled: false },
  { value: 'RHB Islamic Bank Berhad', text: 'RHB Islamic Bank Berhad', disabled: false },
  { value: 'Standard Chartered Saadiq Berhad ', text: 'Standard Chartered Saadiq Berhad ', disabled: false },
  { value: 'Development Financial Institutions (Government-owned banks)', text: 'Development Financial Institutions (Government-owned banks)', disabled: true },
  { value: 'Agro Bank Malaysia', text: 'Agro Bank Malaysia', disabled: false },
  { value: 'Bank Rakyat', text: 'Bank Rakyat', disabled: false },
  { value: 'Bank Simpanan Nasional', text: 'Bank Simpanan Nasional', disabled: false },
  { value: 'Export-Import Bank of Malaysia Berhad (Exim Bank)', text: 'Export-Import Bank of Malaysia Berhad (Exim Bank)', disabled: false },
  { value: 'Bank Perusahaan Kecil & Sederhana Berhad ((Small Medium Enterprise) SME Bank Berhad)', text: 'Bank Perusahaan Kecil & Sederhana Berhad ((Small Medium Enterprise) SME Bank Berhad)', disabled: false },
  { value: 'Sabah Development Bank Berhad (SDB)', text: 'Sabah Development Bank Berhad (SDB)', disabled: false },
];
