import { API } from 'aws-amplify';

interface InvokeLambdaProps {
  name: string,
  path: string,
  method: string,
  header?: object,
  body?: object,
  query?: object,
  settings?: object,
}

export const invokeLambda = async ({
  name,
  path,
  method,
  header,
  body,
  query,
  settings,
}: InvokeLambdaProps) => {
  const opts = {
    headers: {
      ...header,
    },
    queryStringParameters: {
      ...query,
    },
    body,
    ...settings,
  };
  // TODO: find out type checking
  /* @ts-ignore */
  return API[method](name, path, opts);
};
