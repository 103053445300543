import { BankMutationModel } from './bank';
import { Banker } from './banker';
import Company from './company';

export enum BankCaseStatusType {
  NEW = 'new',
  PENDING_DOCS = 'pending_docs',
  PENDING_DOCS_SUBMITTED = 'pending_docs_submitted',
  APPROVED_LO_ISSUED = 'approved_lo_issued',
  PENDING_UNREACHABLE = 'pending_unreachable',
  PENDING_REAPPEALED = 'pending_reappealed',
  REJECT_WRONG_INFO = 'reject_wrong_info',
  REJECT_CANCELLED = 'reject_cancelled',
  SUCCESS_LI_ISSUED = 'success_li_issued',
  REJECT_UNQUALIFIED = 'reject_unqualified',
  REJECT_BANK = 'reject_bank',
  REJECT_CUSTOMER = 'reject_customer',
  REJECT_LO_SIGNED_OTHER_BANK = 'reject_lo_signed_other_bank',
  REJECT_DEVELOPER = 'reject_developer',
}

export class BankCase {
  readonly id?: string = '';

  readonly company?: Company = {} as Company;

  readonly companyID?: string = '';

  readonly affiliateCompanyID?: string = '';

  readonly bank?: BankMutationModel = {} as BankMutationModel;

  readonly bankID?: string = '';

  readonly banker?: Banker = {} as Banker;

  readonly bankerID?: string = '';

  readonly projectID?: string = '';

  readonly userID?: string = '';

  readonly address1?: string = '';

  readonly address2?: string = '';

  readonly address3?: string = '';

  readonly address4?: string = '';

  readonly affiliateID?: string = '';

  readonly buyerLoanAppID?: string = '';

  readonly buyerProfileID?: string = '';

  readonly city?: string = '';

  readonly code?: string = '';

  readonly country?: string = '';

  readonly createdByAwsUserID?: string = '';

  readonly createdByID?: number = 0;

  readonly email?: string = '';

  readonly identity?: string = '';

  readonly identityType?: string = '';

  readonly infoVerified?: boolean = false;

  readonly isUnread?: boolean = false;

  readonly latestUpdate?: string = '';

  readonly latestUpdateAt?: Date = undefined;

  readonly leadID?: number = 0;

  readonly loanAmount?: number = 0;

  readonly loanRatePoints?: number = 0;

  readonly loanYears?: number = 0;

  readonly mobile?: string = '';

  readonly mobilePrefix?: string = '';

  readonly name?: string = '';

  readonly nudgeAt?: Date = undefined;

  readonly postcode?: string = '';

  readonly preferredName?: string = '';

  readonly previousStatus?: string = '';

  readonly referenceNo?: string = '';

  readonly state?: string = '';

  readonly status?: BankCaseStatusType = undefined;

  readonly statusNote?: string = undefined;

  readonly createdAt?: Date = undefined;

  readonly updatedAt?: Date = undefined;

  // for reference purpose
  readonly oldAffiliateID?: number = 0;

  readonly oldBankID?: number = 0;

  readonly oldBankerID?: number = 0;

  readonly oldUserID?: number = 0;

  readonly oldID?: number = 0;

  constructor(bankCase?: BankCase) {
    Object.assign(this, bankCase);
  }
}
