import CountryEntity from '../entities/countryEntity';
import Country from '../models/country';

const countries = [
  {
    label: 'Afghanistan',
    value: 'AF',
    calling_code: '93',
  },
  {
    label: 'Albania',
    value: 'AL',
    calling_code: '355',
  },
  {
    label: 'Algeria',
    value: 'DZ',
    calling_code: '213',
  },
  {
    label: 'American Samoa',
    value: 'AS',
    calling_code: '1',
  },
  {
    label: 'Andorra',
    value: 'AD',
    calling_code: '376',
  },
  {
    label: 'Angola',
    value: 'AO',
    calling_code: '244',
  },
  {
    label: 'Anguilla',
    value: 'AI',
    calling_code: '1',
  },
  {
    label: 'Antarctica',
    value: 'AQ',
    calling_code: '1',
  },
  {
    label: 'Antigua & Barbuda',
    value: 'AG',
    calling_code: '1',
  },
  {
    label: 'Argentina',
    value: 'AR',
    calling_code: '54',
  },
  {
    label: 'Armenia',
    value: 'AM',
    calling_code: '374',
  },
  {
    label: 'Aruba',
    value: 'AW',
    calling_code: '297',
  },
  {
    label: 'Australia',
    value: 'AU',
    calling_code: '61',
  },
  {
    label: 'Austria',
    value: 'AT',
    calling_code: '43',
  },
  {
    label: 'Azerbaijan',
    value: 'AZ',
    calling_code: '994',
  },
  {
    label: 'Bahamas',
    value: 'BS',
    calling_code: '1',
  },
  {
    label: 'Bahrain',
    value: 'BH',
    calling_code: '973',
  },
  {
    label: 'Bangladesh',
    value: 'BD',
    calling_code: '880',
  },
  {
    label: 'Barbados',
    value: 'BB',
    calling_code: '1',
  },
  {
    label: 'Belarus',
    value: 'BY',
    calling_code: '375',
  },
  {
    label: 'Belgium',
    value: 'BE',
    calling_code: '32',
  },
  {
    label: 'Belize',
    value: 'BZ',
    calling_code: '501',
  },
  {
    label: 'Benin',
    value: 'BJ',
    calling_code: '229',
  },
  {
    label: 'Bermuda',
    value: 'BM',
    calling_code: '1',
  },
  {
    label: 'Bhutan',
    value: 'BT',
    calling_code: '975',
  },
  {
    label: 'Bolivia',
    value: 'BO',
    calling_code: '591',
  },
  {
    label: 'Bosnia & Herzegovina',
    value: 'BA',
    calling_code: '387',
  },
  {
    label: 'Botswana',
    value: 'BW',
    calling_code: '267',
  },
  {
    label: 'Brazil',
    value: 'BR',
    calling_code: '55',
  },
  {
    label: 'British Indian Ocean Territory',
    value: 'IO',
    calling_code: '246',
  },
  {
    label: 'Brunei',
    value: 'BN',
    calling_code: '673',
  },
  {
    label: 'Bulgaria',
    value: 'BG',
    calling_code: '359',
  },
  {
    label: 'Burkina Faso',
    value: 'BF',
    calling_code: '226',
  },
  {
    label: 'Burundi',
    value: 'BI',
    calling_code: '257',
  },
  {
    label: 'Cambodia',
    value: 'KH',
    calling_code: '855',
  },
  {
    label: 'Cameroon',
    value: 'CM',
    calling_code: '237',
  },
  {
    label: 'Canada',
    value: 'CA',
    calling_code: '1',
  },
  {
    label: 'Cape Verde',
    value: 'CV',
    calling_code: '238',
  },
  {
    label: 'Cayman Islands',
    value: 'KY',
    calling_code: '1',
  },
  {
    label: 'Central African Republic',
    value: 'CF',
    calling_code: '236',
  },
  {
    label: 'Chad',
    value: 'TD',
    calling_code: '235',
  },
  {
    label: 'Chile',
    value: 'CL',
    calling_code: '56',
  },
  {
    label: 'China',
    value: 'CN',
    calling_code: '86',
  },
  {
    label: 'Colombia',
    value: 'CO',
    calling_code: '57',
  },
  {
    label: 'Comoros',
    value: 'KM',
    calling_code: '269',
  },
  {
    label: 'Congo - Brazzaville',
    value: 'CG',
    calling_code: '242',
  },
  {
    label: 'Congo - Kinshasa',
    value: 'CD',
    calling_code: '243',
  },
  {
    label: 'Cook Islands',
    value: 'CK',
    calling_code: '682',
  },
  {
    label: 'Costa Rica',
    value: 'CR',
    calling_code: '506',
  },
  {
    label: 'Cote D\'Ivoire',
    value: 'CI',
    calling_code: '225',
  },
  {
    label: 'Croatia',
    value: 'HR',
    calling_code: '385',
  },
  {
    label: 'Cuba',
    value: 'CU',
    calling_code: '53',
  },
  {
    label: 'Cyprus',
    value: 'CY',
    calling_code: '357',
  },
  {
    label: 'Czech Republic',
    value: 'CZ',
    calling_code: '420',
  },
  {
    label: 'Denmark',
    value: 'DK',
    calling_code: '45',
  },
  {
    label: 'Djibouti',
    value: 'DJ',
    calling_code: '253',
  },
  {
    label: 'Dominica',
    value: 'DM',
    calling_code: '1',
  },
  {
    label: 'Dominican Republic',
    value: 'DO',
    calling_code: '1',
  },
  {
    label: 'Ecuador',
    value: 'EC',
    calling_code: '593',
  },
  {
    label: 'Egypt',
    value: 'EG',
    calling_code: '20',
  },
  {
    label: 'El Salvador',
    value: 'SV',
    calling_code: '503',
  },
  {
    label: 'Equatorial Guinea',
    value: 'GQ',
    calling_code: '240',
  },
  {
    label: 'Eritrea',
    value: 'ER',
    calling_code: '291',
  },
  {
    label: 'Estonia',
    value: 'EE',
    calling_code: '372',
  },
  {
    label: 'Ethiopia',
    value: 'ET',
    calling_code: '251',
  },
  {
    label: 'Falkland Islands (Malvinas)',
    value: 'FK',
    calling_code: '500',
  },
  {
    label: 'Faroe Islands',
    value: 'FO',
    calling_code: '298',
  },
  {
    label: 'Fiji',
    value: 'FJ',
    calling_code: '679',
  },
  {
    label: 'Finland',
    value: 'FI',
    calling_code: '358',
  },
  {
    label: 'France',
    value: 'FR',
    calling_code: '33',
  },
  {
    label: 'French Guiana',
    value: 'GF',
    calling_code: '594',
  },
  {
    label: 'French Polynesia',
    value: 'PF',
    calling_code: '689',
  },
  {
    label: 'Gabon',
    value: 'GA',
    calling_code: '241',
  },
  {
    label: 'Gambia',
    value: 'GM',
    calling_code: '220',
  },
  {
    label: 'Georgia',
    value: 'GE',
    calling_code: '995',
  },
  {
    label: 'Germany',
    value: 'DE',
    calling_code: '49',
  },
  {
    label: 'Ghana',
    value: 'GH',
    calling_code: '233',
  },
  {
    label: 'Gibraltar',
    value: 'GI',
    calling_code: '350',
  },
  {
    label: 'Greece',
    value: 'GR',
    calling_code: '30',
  },
  {
    label: 'Greenland',
    value: 'GL',
    calling_code: '299',
  },
  {
    label: 'Grenada',
    value: 'GD',
    calling_code: '1',
  },
  {
    label: 'Guadeloupe',
    value: 'GP',
    calling_code: '590',
  },
  {
    label: 'Guam',
    value: 'GU',
    calling_code: '1',
  },
  {
    label: 'Guatemala',
    value: 'GT',
    calling_code: '502',
  },
  {
    label: 'Guinea',
    value: 'GN',
    calling_code: '224',
  },
  {
    label: 'Guinea-Bissau',
    value: 'GW',
    calling_code: '245',
  },
  {
    label: 'Guyana',
    value: 'GY',
    calling_code: '592',
  },
  {
    label: 'Haiti',
    value: 'HT',
    calling_code: '509',
  },
  {
    label: 'Honduras',
    value: 'HN',
    calling_code: '504',
  },
  {
    label: 'Hong Kong',
    value: 'HK',
    calling_code: '852',
  },
  {
    label: 'Hungary',
    value: 'HU',
    calling_code: '36',
  },
  {
    label: 'Iceland',
    value: 'IS',
    calling_code: '354',
  },
  {
    label: 'India',
    value: 'IN',
    calling_code: '91',
  },
  {
    label: 'Indonesia',
    value: 'ID',
    calling_code: '62',
  },
  {
    label: 'Iran',
    value: 'IR',
    calling_code: '98',
  },
  {
    label: 'Iraq',
    value: 'IQ',
    calling_code: '964',
  },
  {
    label: 'Ireland',
    value: 'IE',
    calling_code: '353',
  },
  {
    label: 'Israel',
    value: 'IL',
    calling_code: '972',
  },
  {
    label: 'Italy',
    value: 'IT',
    calling_code: '39',
  },
  {
    label: 'Jamaica',
    value: 'JM',
    calling_code: '1',
  },
  {
    label: 'Japan',
    value: 'JP',
    calling_code: '81',
  },
  {
    label: 'Jordan',
    value: 'JO',
    calling_code: '962',
  },
  {
    label: 'Kazakhstan',
    value: 'KZ',
    calling_code: '7',
  },
  {
    label: 'Kenya',
    value: 'KE',
    calling_code: '254',
  },
  {
    label: 'Kiribati',
    value: 'KI',
    calling_code: '686',
  },
  {
    label: 'Kosovo',
    value: 'XK',
    calling_code: '383',
  },
  {
    label: 'Kuwait',
    value: 'KW',
    calling_code: '965',
  },
  {
    label: 'Kyrgyzstan',
    value: 'KG',
    calling_code: '996',
  },
  {
    label: 'Laos',
    value: 'LA',
    calling_code: '856',
  },
  {
    label: 'Latvia',
    value: 'LV',
    calling_code: '371',
  },
  {
    label: 'Lebanon',
    value: 'LB',
    calling_code: '961',
  },
  {
    label: 'Lesotho',
    value: 'LS',
    calling_code: '266',
  },
  {
    label: 'Liberia',
    value: 'LR',
    calling_code: '231',
  },
  {
    label: 'Libya',
    value: 'LY',
    calling_code: '218',
  },
  {
    label: 'Liechtenstein',
    value: 'LI',
    calling_code: '423',
  },
  {
    label: 'Lithuania',
    value: 'LT',
    calling_code: '370',
  },
  {
    label: 'Luxembourg',
    value: 'LU',
    calling_code: '352',
  },
  {
    label: 'Macao',
    value: 'MO',
    calling_code: '853',
  },
  {
    label: 'Madagascar',
    value: 'MG',
    calling_code: '261',
  },
  {
    label: 'Malawi',
    value: 'MW',
    calling_code: '265',
  },
  {
    label: 'Malaysia',
    value: 'MY',
    calling_code: '60',
    states: [
      { label: 'Johor', value: 'Johor' },
      { label: 'Kedah', value: 'Kedah' },
      { label: 'Kelantan', value: 'Kelantan' },
      { label: 'Melaka', value: 'Melaka' },
      { label: 'Negeri Sembilan', value: 'Negeri Sembilan' },
      { label: 'Pahang', value: 'Pahang' },
      { label: 'Perlis', value: 'Perlis' },
      { label: 'Perak', value: 'Perak' },
      { label: 'Pulau Pinang', value: 'Penang' },
      { label: 'Sabah', value: 'Sabah' },
      { label: 'Sarawak', value: 'Sarawak' },
      { label: 'Selangor', value: 'Selangor' },
      { label: 'Terengganu', value: 'Terengganu' },
      { label: 'Wilayah Persekutuan Kuala Lumpur', value: 'Kuala Lumpur' },
      { label: 'Wilayah Persekutuan Labuan', value: 'Labuan' },
      { label: 'Wilayah Persekutuan Putrajaya', value: 'Putrajaya' },
    ],
  },
  {
    label: 'Maldives',
    value: 'MV',
    calling_code: '960',
  },
  {
    label: 'Mali',
    value: 'ML',
    calling_code: '223',
  },
  {
    label: 'Malta',
    value: 'MT',
    calling_code: '356',
  },
  {
    label: 'Marshall Islands',
    value: 'MH',
    calling_code: '692',
  },
  {
    label: 'Martinique',
    value: 'MQ',
    calling_code: '596',
  },
  {
    label: 'Mauritania',
    value: 'MR',
    calling_code: '222',
  },
  {
    label: 'Mauritius',
    value: 'MU',
    calling_code: '230',
  },
  {
    label: 'Mexico',
    value: 'MX',
    calling_code: '52',
  },
  {
    label: 'Micronesia',
    value: 'FM',
    calling_code: '691',
  },
  {
    label: 'Moldova',
    value: 'MD',
    calling_code: '373',
  },
  {
    label: 'Monaco',
    value: 'MC',
    calling_code: '377',
  },
  {
    label: 'Mongolia',
    value: 'MN',
    calling_code: '976',
  },
  {
    label: 'Montenegro',
    value: 'ME',
    calling_code: '382',
  },
  {
    label: 'Montserrat',
    value: 'MS',
    calling_code: '1',
  },
  {
    label: 'Morocco',
    value: 'MA',
    calling_code: '212',
  },
  {
    label: 'Mozambique',
    value: 'MZ',
    calling_code: '258',
  },
  {
    label: 'Myanmar',
    value: 'MM',
    calling_code: '95',
  },
  {
    label: 'Namibia',
    value: 'NA',
    calling_code: '264',
  },
  {
    label: 'Nauru',
    value: 'NR',
    calling_code: '674',
  },
  {
    label: 'Nepal',
    value: 'NP',
    calling_code: '977',
  },
  {
    label: 'Netherlands',
    value: 'NL',
    calling_code: '31',
  },
  {
    label: 'New Caledonia',
    value: 'NC',
    calling_code: '687',
  },
  {
    label: 'New Zealand',
    value: 'NZ',
    calling_code: '64',
  },
  {
    label: 'Nicaragua',
    value: 'NI',
    calling_code: '505',
  },
  {
    label: 'Niger',
    value: 'NE',
    calling_code: '227',
  },
  {
    label: 'Nigeria',
    value: 'NG',
    calling_code: '234',
  },
  {
    label: 'Niue',
    value: 'NU',
    calling_code: '683',
  },
  {
    label: 'Norfolk Island',
    value: 'NF',
    calling_code: '672',
  },
  {
    label: 'North Korea',
    value: 'KP',
    calling_code: '850',
  },
  {
    label: 'North Macedonia',
    value: 'MK',
    calling_code: '389',
  },
  {
    label: 'Northern Mariana Islands',
    value: 'MP',
    calling_code: '1',
  },
  {
    label: 'Norway',
    value: 'NO',
    calling_code: '47',
  },
  {
    label: 'Oman',
    value: 'OM',
    calling_code: '968',
  },
  {
    label: 'Pakistan',
    value: 'PK',
    calling_code: '92',
  },
  {
    label: 'Palau',
    value: 'PW',
    calling_code: '680',
  },
  {
    label: 'Palestinian',
    value: 'PS',
    calling_code: '970',
  },
  {
    label: 'Panama',
    value: 'PA',
    calling_code: '507',
  },
  {
    label: 'Papua New Guinea',
    value: 'PG',
    calling_code: '675',
  },
  {
    label: 'Paraguay',
    value: 'PY',
    calling_code: '595',
  },
  {
    label: 'Peru',
    value: 'PE',
    calling_code: '51',
  },
  {
    label: 'Philippines',
    value: 'PH',
    calling_code: '63',
  },
  {
    label: 'Poland',
    value: 'PL',
    calling_code: '48',
  },
  {
    label: 'Portugal',
    value: 'PT',
    calling_code: '351',
  },
  {
    label: 'Puerto Rico',
    value: 'PR',
    calling_code: '1',
  },
  {
    label: 'Qatar',
    value: 'QA',
    calling_code: '974',
  },
  {
    label: 'Reunion',
    value: 'RE',
    calling_code: '262',
  },
  {
    label: 'Romania',
    value: 'RO',
    calling_code: '40',
  },
  {
    label: 'Russian',
    value: 'RU',
    calling_code: '7',
  },
  {
    label: 'Rwanda',
    value: 'RW',
    calling_code: '250',
  },
  {
    label: 'Samoa',
    value: 'WS',
    calling_code: '685',
  },
  {
    label: 'San Marino',
    value: 'SM',
    calling_code: '378',
  },
  {
    label: 'Sao Tome & Principe',
    value: 'ST',
    calling_code: '239',
  },
  {
    label: 'Saudi Arabia',
    value: 'SA',
    calling_code: '966',
  },
  {
    label: 'Senegal',
    value: 'SN',
    calling_code: '221',
  },
  {
    label: 'Serbia',
    value: 'CS',
    calling_code: '381',
  },
  {
    label: 'Seychelles',
    value: 'SC',
    calling_code: '248',
  },
  {
    label: 'Sierra Leone',
    value: 'SL',
    calling_code: '232',
  },
  {
    label: 'Singapore',
    value: 'SG',
    calling_code: '65',
  },
  {
    label: 'Slovakia',
    value: 'SK',
    calling_code: '421',
  },
  {
    label: 'Slovenia',
    value: 'SI',
    calling_code: '386',
  },
  {
    label: 'Solomon Islands',
    value: 'SB',
    calling_code: '677',
  },
  {
    label: 'Somalia',
    value: 'SO',
    calling_code: '252',
  },
  {
    label: 'South Africa',
    value: 'ZA',
    calling_code: '27',
  },
  {
    label: 'South Korea',
    value: 'KR',
    calling_code: '82',
  },
  {
    label: 'South Sudan',
    value: 'SS',
    calling_code: '211',
  },
  {
    label: 'Spain',
    value: 'ES',
    calling_code: '34',
  },
  {
    label: 'Sri Lanka',
    value: 'LK',
    calling_code: '94',
  },
  {
    label: 'St. Barthelemy',
    value: 'BL',
    calling_code: '590',
  },
  {
    label: 'St. Helena',
    value: 'SH',
    calling_code: '290',
  },
  {
    label: 'St. Kitts & Nevis',
    value: 'KN',
    calling_code: '1',
  },
  {
    label: 'St. Lucia',
    value: 'LC',
    calling_code: '1',
  },
  {
    label: 'St. Martin',
    value: 'SX',
    calling_code: '590',
  },
  {
    label: 'St. Pierre & Miquelon',
    value: 'PM',
    calling_code: '508',
  },
  {
    label: 'St. Vincent & Grenadines',
    value: 'VC',
    calling_code: '1',
  },
  {
    label: 'Sudan',
    value: 'SD',
    calling_code: '249',
  },
  {
    label: 'Suriname',
    value: 'SR',
    calling_code: '597',
  },
  {
    label: 'Sweden',
    value: 'SE',
    calling_code: '46',
  },
  {
    label: 'Switzerland',
    value: 'CH',
    calling_code: '41',
  },
  {
    label: 'Syrian',
    value: 'SY',
    calling_code: '963',
  },
  {
    label: 'Taiwan',
    value: 'TW',
    calling_code: '886',
  },
  {
    label: 'Tajikistan',
    value: 'TJ',
    calling_code: '992',
  },
  {
    label: 'Tanzania',
    value: 'TZ',
    calling_code: '255',
  },
  {
    label: 'Thailand',
    value: 'TH',
    calling_code: '66',
  },
  {
    label: 'Timor-Leste',
    value: 'TL',
    calling_code: '670',
  },
  {
    label: 'Togo',
    value: 'TG',
    calling_code: '228',
  },
  {
    label: 'Tokelau',
    value: 'TK',
    calling_code: '690',
  },
  {
    label: 'Tonga',
    value: 'TO',
    calling_code: '676',
  },
  {
    label: 'Trinidad & Tobago',
    value: 'TT',
    calling_code: '1',
  },
  {
    label: 'Tunisia',
    value: 'TN',
    calling_code: '216',
  },
  {
    label: 'Turkey',
    value: 'TR',
    calling_code: '90',
  },
  {
    label: 'Turkmenistan',
    value: 'TM',
    calling_code: '993',
  },
  {
    label: 'Turks & Caicos Islands',
    value: 'TC',
    calling_code: '1',
  },
  {
    label: 'Tuvalu',
    value: 'TV',
    calling_code: '688',
  },
  {
    label: 'Uganda',
    value: 'UG',
    calling_code: '256',
  },
  {
    label: 'Ukraine',
    value: 'UA',
    calling_code: '380',
  },
  {
    label: 'United Arab Emirates',
    value: 'AE',
    calling_code: '971',
  },
  {
    label: 'United Kingdom',
    value: 'GB',
    calling_code: '44',
  },
  {
    label: 'United States',
    value: 'US',
    calling_code: '1',
  },
  {
    label: 'Uruguay',
    value: 'UY',
    calling_code: '598',
  },
  {
    label: 'Uzbekistan',
    value: 'UZ',
    calling_code: '998',
  },
  {
    label: 'Vanuatu',
    value: 'VU',
    calling_code: '678',
  },
  {
    label: 'Vatican City',
    value: 'VA',
    calling_code: '39',
  },
  {
    label: 'Venezuela',
    value: 'VE',
    calling_code: '58',
  },
  {
    label: 'Vietnam',
    value: 'VN',
    calling_code: '84',
  },
  {
    label: 'Virgin Islands, British',
    value: 'VG',
    calling_code: '1',
  },
  {
    label: 'Virgin Islands, U.S.',
    value: 'VI',
    calling_code: '1',
  },
  {
    label: 'Wallis & Futuna',
    value: 'WF',
    calling_code: '681',
  },
  {
    label: 'Yemen',
    value: 'YE',
    calling_code: '967',
  },
  {
    label: 'Zambia',
    value: 'ZM',
    calling_code: '260',
  },
  {
    label: 'Zimbabwe',
    value: 'ZW',
    calling_code: '263',
  },
];

export default countries;

export const countriesCollection: Country[] = countries.map((c) => (
  Country.fromEntity(CountryEntity.fromData(c))
));

export const countriesIndex: {
  [code: string]: Country;
} = countries.reduce(
  (ac, a) => ({ ...ac, [a.value]: Country.fromEntity(CountryEntity.fromData(a)) }),
  {},
);

export const countriesCallingCodeIndex: {
  [calling_code: string]: Country;
} = countries.reduce(
  (ac, a) => ({ ...ac, [a.calling_code]: Country.fromEntity(CountryEntity.fromData(a)) }),
  {},
);

export const countryLabel = (code: string) => countries.find((c) => c.value === code)?.label ?? 'N/A';

export const nationalityOptions = [
  ...countries,
  { label: 'Not Applicable', value: 'Not Applicable' },
];
