import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import qs from 'query-string';
import { isError } from 'src/utils';
import { invokeLambda } from 'src/utils/api';
import { isAWSError } from 'src/utils/aws';
import { ProjectMutationModel, ProjectModel, Project } from 'src/models/project';
import { DefaultAPIResponse } from 'src/models/root';

interface ProjectContext {
  project: ProjectModel;
  projectChanges: boolean;
  projects: ProjectModel[];
  projectsCount: number;
  projectError?: string;
  projectLoading: boolean;
  projectSubmitting: boolean;
  findProject: (id: string, query?: object) => Promise<boolean>;
  createProject: (p: ProjectModel, csrfToken: string) => Promise<ProjectModel>;
  setProject: Dispatch<SetStateAction<ProjectModel>>;
  setProjects: Dispatch<SetStateAction<ProjectModel[]>>;
  setProjectChanges: Dispatch<SetStateAction<boolean>>;
  updateProject: (p: ProjectMutationModel) => Promise<DefaultAPIResponse>;
  listProjects: (query?: object, companyID?: string) => Promise<DefaultAPIResponse>;
}

const projectContext = createContext<ProjectContext>({
  project: {} as ProjectModel,
  projectChanges: false,
  projects: [],
  projectsCount: 0,
  projectError: '',
  projectLoading: false,
  projectSubmitting: false,
  findProject: () => Promise.resolve(false),
  createProject: () => Promise.resolve({} as ProjectModel),
  setProject: () => Promise.resolve({} as ProjectModel),
  setProjects: () => Promise.resolve({} as ProjectModel),
  setProjectChanges: () => Promise.resolve(false),
  updateProject: () => Promise.resolve({} as DefaultAPIResponse),
  listProjects: () => Promise.resolve({} as DefaultAPIResponse),
});

export function useProvideProject(): ProjectContext {
  const { companyID } = useParams<{ companyID: string }>();
  const [project, setProject] = useState(new Project());
  const [projects, setProjects] = useState<Project[]>([]);
  const [projectsCount, setProjectsCount] = useState<number>(0);
  const [projectError, setProjectError] = useState<string>();
  const [projectLoading, setProjectLoading] = useState(false);
  const [projectSubmitting, setProjectSubmitting] = useState(false);
  const [projectChanges, setProjectChanges] = useState(false);

  const handleError = (err: any) => {
    let errMsg = 'An error occurred';
    if (isAWSError(err)) {
      errMsg = err.response?.data.error_message || 'An error occurred';
      setProjectError(err.response?.data.error_message);
    } else if (isError(err)) {
      errMsg = err.message;
      setProjectError(err.message);
    } else {
      setProjectError('An error occurred');
    }
    return errMsg;
  };

  /**
   * Find Project
   */
  const findProject = async (id: string, query = {}): Promise<boolean> => {
    setProjectLoading(true);
    try {
      const path = `/projects/${id}`;
      const res = await invokeLambda({
        name: 'project',
        method: 'get',
        header: {
          'x-company-id': companyID,
        },
        query,
        path,
      });
      if (res) {
        setProject(res);
      }
    } catch (err) {
      setProjectLoading(false);
      handleError(err);
      return false;
    }
    setProjectLoading(false);
    return true;
  };

  /**
   * Create project
   */
  const createProject = async (p: ProjectModel): Promise<Project> => {
    setProjectSubmitting(true);
    setProjectError(undefined);
    let res;
    try {
      const path = '/projects';
      res = await invokeLambda({
        name: 'project',
        method: 'post',
        header: {
          'x-company-id': companyID,
        },
        body: p,
        path,
      });
      if (res) {
        setProject(res);
      }
    } catch (err) {
      setProjectSubmitting(false);
      handleError(err);
      return res;
    }
    setProjectSubmitting(false);
    return res;
  };

  /**
   * Update project
   */
  const updateProject = async (p: ProjectMutationModel): Promise<DefaultAPIResponse> => {
    setProjectSubmitting(true);
    setProjectError(undefined);
    try {
      const path = `/projects/${p.id}`;
      const res = await invokeLambda({
        name: 'project',
        method: 'put',
        header: {
          'x-company-id': companyID,
        },
        body: p,
        path,
      });
      if (res) {
        setProject(res);
      }
    } catch (err) {
      setProjectSubmitting(false);
      const errMsg = handleError(err);
      return {
        message: errMsg,
        status: 'rejected',
      };
    }
    setProjectSubmitting(false);
    return {
      status: 'success',
    };
  };

  /**
 * List projects
 */
  const listProjects = async (query = {}, cID = ''): Promise<DefaultAPIResponse> => {
    setProjectSubmitting(true);
    setProjectError(undefined);
    const xcompanyID = companyID ?? cID;
    const q = qs.parse(qs.stringify(query));
    try {
      const path = '/projects';
      const res = await invokeLambda({
        name: 'project',
        method: 'get',
        header: {
          'x-company-id': xcompanyID,
        },
        query: q,
        path,
      });
      if (res && res.items) {
        setProjects(res.items);
        setProjectsCount(res.count);
        return {
          body: res.items,
          status: 'success',
        };
      }
      setProjects([]);
      setProjectsCount(0);
    } catch (err) {
      setProjectSubmitting(false);
      const errMsg = handleError(err);
      return {
        status: 'rejected',
        message: errMsg,
      };
    }
    setProjectSubmitting(false);
    return {
      status: 'success',
    };
  };

  return {
    project,
    projectChanges,
    projects,
    projectsCount,
    projectError,
    projectLoading,
    projectSubmitting,
    findProject,
    createProject,
    setProject,
    setProjects,
    setProjectChanges,
    updateProject,
    listProjects,
  };
}

export const useProjectContext = () => useContext(projectContext);

export function ProvideProject({
  children,
}: {
  children: ReactNode,
}) {
  const project = useProvideProject();
  return (
    <projectContext.Provider value={project}>
      {children}
    </projectContext.Provider>
  );
}
