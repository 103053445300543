import {
  useContext, useState, createContext, ReactNode,
} from 'react';
import { isError } from 'src/utils';
import { invokeLambda } from 'src/utils/api';
import { isAWSError } from 'src/utils/aws';
import { CompanyCurrency } from 'src/models/companyCurrency';
import { CompanySetting } from 'src/models/companySetting';
import Company from '../models/company';

interface CompanyContext {
  company: Company;
  companyCurrencies: CompanyCurrency[];
  companySetting?: CompanySetting;
  companyError?: string;
  companyLoading: boolean;
  companies: Company[];
  companiesCount: number;
  findCompany: (id: string) => Promise<boolean>;
  findCompanySetting: (id: string) => Promise<void>;
  listCompanyCurrencies: (id: string) => Promise<void>;
  listCompanies: (query?: object) => Promise<boolean>;
}

const companyContext = createContext<CompanyContext>({
  company: {} as Company,
  companyCurrencies: [],
  companySetting: {} as CompanySetting,
  companyError: '',
  companyLoading: false,
  companies: [],
  companiesCount: 0,
  findCompany: () => Promise.resolve(false),
  findCompanySetting: () => Promise.resolve(),
  listCompanyCurrencies: () => Promise.resolve(),
  listCompanies: () => Promise.resolve(false),
});

export function useProvideCompany(): CompanyContext {
  const [company, setCompany] = useState(new Company());
  const [companyCurrencies, setCompanyCurrencies] = useState<CompanyCurrency[]>([]);
  const [companySetting, setCompanySetting] = useState<CompanySetting>();
  const [companyError, setCompanyError] = useState<string>();
  const [companyLoading, setCompanyLoading] = useState(false);
  const [companies, setCompanies] = useState<Company []>([]);
  const [companiesCount, setCompaniesCount] = useState<number>(0);

  const handleError = (err: any) => {
    if (isAWSError(err)) {
      setCompanyError(err.message);
    } else if (isError(err)) {
      setCompanyError(err.message);
    } else {
      setCompanyError('An error occurred');
    }
  };

  /**
   * Find Company
   */
  const findCompany = async (id: string): Promise<boolean> => {
    setCompanyLoading(true);
    try {
      const path = `/companies_v2/${id}`;
      const res = await invokeLambda({
        name: 'company',
        method: 'get',
        path,
      });
      if (res) {
        setCompany(res);
      }
    } catch (err) {
      setCompanyLoading(false);
      handleError(err);
      return false;
    }
    setCompanyLoading(false);
    return true;
  };

  const findCompanySetting = async (id: string): Promise<void> => {
    setCompanyLoading(true);
    setCompanyError(undefined);
    try {
      const path = `/companies/${id}/setting/`;
      const res = await invokeLambda({
        name: 'company',
        method: 'get',
        path,
      });
      if (res) {
        setCompanySetting(res);
      }
    } catch (err) {
      handleError(err);
    }
    setCompanyLoading(false);
  };

  const listCompanyCurrencies = async (companyID: string): Promise<void> => {
    setCompanyLoading(true);
    setCompanyError(undefined);
    try {
      const path = '/currencies';
      const res = await invokeLambda({
        name: 'company',
        method: 'get',
        path,
        header: {
          'x-company-id': companyID,
        },
      });
      if (res && res.items) {
        setCompanyCurrencies(res.items);
      }
    } catch (err) {
      handleError(err);
    }
    setCompanyLoading(false);
  };

  const listCompanies = async (query = {}): Promise<boolean> => {
    setCompanyError(undefined);
    try {
      const path = '/companies_v2';
      const res = await invokeLambda({
        name: 'company',
        method: 'get',
        query,
        path,
      });
      if (res && res.items) {
        setCompanies(res.items);
        setCompaniesCount(res.count);
      } else {
        setCompanies([]);
        setCompaniesCount(0);
      }
    } catch (err) {
      handleError(err);
      return false;
    }
    return true;
  };

  return {
    company,
    companyCurrencies,
    companySetting,
    companyError,
    companyLoading,
    companies,
    companiesCount,
    findCompany,
    findCompanySetting,
    listCompanyCurrencies,
    listCompanies,
  };
}

export const useCompanyContext = () => useContext(companyContext);

export function ProvideCompany({
  children,
}: {
  children: ReactNode,
}) {
  const company = useProvideCompany();
  return (
    <companyContext.Provider value={company}>
      {children}
    </companyContext.Provider>
  );
}
