export const isStringArray = (test: any): test is string[] => (
  Array.isArray(test) && !test.some((value) => typeof value !== 'string')
);

export const isArray = (test: any): test is any[] => (
  Array.isArray(test)
);

export const isData = (test: any): test is {
  [name: string]: any;
} => (
  typeof test === 'object'
);

export const isArrayData = (test: any): test is {
  [name: string]: any
}[] => {
  if (isArray(test)) {
    return test.every((t) => isData(t));
  }
  return false;
};

export const isError = (test: any): test is Error => test && test.stack && test.message;

export const timeout = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const camelToTitle = (str: string) => str.replace(/([A-Z])/g, (match) => ` ${match}`).replace(/^./, (match) => match.toUpperCase());

export const valueToText = (array: any, value: any) => {
  const arrayItem = array.find((x: any) => x.value === value);
  if (arrayItem && arrayItem.text) {
    return arrayItem.text;
  }
  return '';
};

// format phone number: 012-xxxxxxxx / +012-xxxxxxxx
export const formatPhoneNumber = (prefix: string, mobile: string, withPlus?: boolean) => {
  const firstOfMobile = mobile.charAt(0);

  const newPrefix = `${withPlus ? '+' : ''}${prefix}${firstOfMobile}`;
  const newMobile = mobile.substring(1);

  return `${newPrefix}-${newMobile}`;
};
