import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  NormalizedCacheObject,
  concat,
  gql,
  InMemoryCache,
} from '@apollo/client';
import { useEffect } from 'react';
import { Auth, API } from 'aws-amplify';
import moment from 'moment';
import 'moment-timezone';
import { CssBaseline, useMediaQuery } from '@material-ui/core';
import { ThemeProvider, useTheme } from '@material-ui/core/styles';
import DesktopNav from 'src/components/DesktopNav';
import Routes from 'src/components/Routes';
import { setupAmplifyConfig } from 'src/config';
import { ProvideAlert } from 'src/hooks/useAlert';
import { ProvideAuth } from 'src/hooks/useAuth';
import { ProvideCompany } from 'src/hooks/useCompany';
import { ProvideCompanyBranch } from 'src/hooks/useCompanyBranch';
import { ProvideProject } from 'src/hooks/useProject';
import globalTheme from 'src/styles/globalTheme';
import { cache } from './cache';
import { FilterProvider } from './hooks/useFilter';
import GA from './googleAnalytics';
import { GenerateAnalyticsReportProvider } from './hooks/useGenerateAnalyticsReport';

const apiLink = new HttpLink({
  uri: process.env.REACT_APP_DATALAKE_API_URL,
  credentials: 'include',
});

const headerMiddleware = new ApolloLink((operation, forward) => {
  // get companyID from cache (useReactiveVar wont work here)
  const context = operation.getContext();
  const ctxCache: InMemoryCache = context.cache as InMemoryCache;
  const data = ctxCache.readQuery<{ companyID?: string | undefined }>({
    query: gql`
      query companyIdQuery {
        companyID @client
      }
    `,
  });

  // pass companyID to header
  const companyID = data?.companyID ?? '';
  if (companyID) {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        'x-company-id': companyID,
      },
    }));
  }

  return forward(operation);
});

const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  cache,
  link: concat(headerMiddleware, apiLink),
  connectToDevTools: true,
});

function App() {
  useEffect(() => {
    const config = setupAmplifyConfig();
    Auth.configure(config.Auth);
    API.configure(config.API);
  }, []);
  const mobileScreen = useMediaQuery(useTheme().breakpoints.down('sm'));

  moment.tz.setDefault('Asia/Kuala_Lumpur');

  return (
    <div className="App">
      <ThemeProvider theme={globalTheme}>
        <CssBaseline />
        <ProvideAlert>
          <ProvideAuth>
            <ProvideCompany>
              <ProvideCompanyBranch>
                <ProvideProject>
                  <GA.RouteTracker />
                  <FilterProvider>
                    <GenerateAnalyticsReportProvider>
                      <ApolloProvider client={client}>
                        {
                      !mobileScreen ? (
                        <DesktopNav />
                      ) : ''
                    }
                        <Routes />
                      </ApolloProvider>
                    </GenerateAnalyticsReportProvider>
                  </FilterProvider>
                </ProvideProject>
              </ProvideCompanyBranch>
            </ProvideCompany>
          </ProvideAuth>
        </ProvideAlert>
      </ThemeProvider>
    </div>
  );
}

export default App;
