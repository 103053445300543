import CountryEntity from '../entities/countryEntity';
import CountryState from './countryState';

export default class Country {
  readonly label: string;

  readonly value: string;

  readonly callingCode: string;

  readonly states?: CountryState[];

  constructor({
    label,
    value,
    callingCode,
    states,
  }: {
    label?: string,
    value?: string,
    callingCode?: string,
    states?: CountryState[],
  }) {
    this.label = label ?? '';
    this.value = value ?? '';
    this.callingCode = callingCode ?? '';
    this.states = states;
  }

  static fromEntity(entity: CountryEntity): Country {
    return new Country({
      label: entity.label,
      value: entity.value,
      callingCode: entity.callingCode,
      states: entity.states
        ? entity.states.map((s) => CountryState.fromEntity(s))
        : undefined,
    });
  }
}
